import React from 'react';
import { withRouter } from 'react-router-dom';
import { isLoaded } from 'react-redux-firebase';
import * as Sentry from '@sentry/browser';

import Loading from 'components/Loading';
import { enhance } from 'utils';
import { loginPath } from 'constants/routes';

const withAuthorization = (condition, path = loginPath) => (Component) => {
  const rules = Array.isArray(condition) ? condition : [{condition, path}];

  class WithAuthorization extends React.Component {
    componentWillUpdate(nextProps) {
      const { auth, profile, history, match: { url } } = nextProps || {};

      if (isLoaded(auth) && isLoaded(profile)) {
        rules.some(({condition, path = loginPath}) => {
          if (!condition(nextProps)) {
            const msg = `You don't have access to this page ${url}`;
            if (process.env.NODE_ENV !== 'development') {
              Sentry.withScope(scope => {
                scope.setExtra('nextProps', nextProps);
                Sentry.captureMessage(msg);
              });
            } else {
              console.log(msg, nextProps);
            }
            history.push(path);
            return true;
          }
          return false;
        });
      }
    }

    render() {
      const { auth, profile, session } = this.props;

      if (isLoaded(auth) && isLoaded(profile) && session.isReady) {
        if (rules.some(rule => !rule.condition(this.props))) {
          return null;
        } else {
          return (<Component {...this.props} />);
        }
      }

      return (
        <Loading {...this.props} />
      );
    }
  }

  return enhance(withRouter(WithAuthorization));
};

export default withAuthorization;
