import React, { Fragment } from 'react';

import WithAuthorization from 'components/WithAuthorization';
import { authCondition } from 'utils';

import { Teaser } from 'components/Screen';
import LinkInner from 'components/LinkInner';
import Anchor from 'components/Anchor';
import TypeformEmbed from 'components/TypeformEmbed';
import Masonry, { MasonryItem } from 'components/Masonry';
import Video from 'components/Video'

// Images
import studios1 from 'images/studios1.jpg';
import studios2 from 'images/studios2.jpg';
import studios3 from 'images/studios3.jpg';
import studios4 from 'images/studios4.jpg';
import studios5 from 'images/studios5.jpg';
import studios6 from 'images/studios6.jpg';

class Studios extends Teaser {
  renderContent() {
    const { embed } = this.props;

    return (<Fragment>
      <section className="imagebg text-center" data-overlay="1" style={{backgroundColor: 'black'}}>
        <div className="container" style={{paddingTop: '30px'}}>
          <div className="row">
            <div className="col-md-12 ">
              <h1>BlitzPrep Studios</h1>
              <div className="col-md-12 col-lg-12">
                <div className="col-md-12 col-lg-10" style={{margin:'auto'}}>
                  <h4 style={{paddingBottom:'10px'}}>BlitzPrep is the Netflix of athlete transition. We are filmmakers, viral content creators and feature film producers/directors, all of which have specific touch points to football. We have played the game, filmed the game, and captured libraries of stories about life beyond the game. As a collective, we are producing endless series of content that dive deeper in the hearts of the men behind the helmets and challenge how we think about our heroes.
                  </h4>
                </div>
                {
                  !embed &&
                  <LinkInner title={'Get Started'} path={'#form'} style={{marginBottom: '30px'}}/>
                }
                <img alt="Studios" src={studios1} style={{width:'100%', paddingBottom:'40px'}} />
                <h2>#MORETHAN</h2>
                <h4 style={{paddingBottom:'40px'}}>Our flagship series of player interviews that explores their intimate unknowns off the field. Because at BlitzPrep, we know you are "More Than" just an athlete.
</h4>
                <LinkInner title={'Talk To A Peer Coach'} path={'#'} style={{marginBottom:'30px'}} />
                <div style={{ position: 'relative', paddingBottom: '40px' }}>
                  <Video source={'https://vimeo.com/304506010/f375ce371e'} />
                </div>
                <h2>#RealTalk</h2>
                <div className="row">
                  <div className="col-md-12">
                  </div>
                  <div className="col-md-12" >
                    <h4 style={{paddingBottom:'40px'}}>A series that features former players discussing specific BlitzPrep University curriculum topics through their own personal experiences. The students of the game have become the teachers.
</h4>
                  </div>
                </div>
                <LinkInner title={'Talk To A Success Coach'} path={'#'} style={{marginBottom:'30px'}} />
                <div style={{ position: 'relative', paddingBottom: '40px' }}>
                  <Video source={'https://vimeo.com/304504634/74e1d8038b'} />
                </div>
                <h2>Docu-Series</h2>
                <div className="row">
                  <div className="col-md-12">
                  </div>
                  <div className="col-md-12" >
                    <h4 style={{paddingBottom:'40px'}}>Where most shows go "inside the huddle", we go "inside the home". This series pulls back the curtains on some of the most brilliant football minds of all time...from the living room.
</h4>
                  </div>
                </div>
                <LinkInner title={'Talk To A Success Coach'} path={'#'} style={{marginBottom:'30px'}} />
                <img alt="Coaching" src={studios2} style={{width:'100%', marginTop:'40px'}} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="unpad bg--dark" style={{ paddingBottom: '75px', backgroundColor:'black'}}>
        <Masonry extraClass="col-11">
          <MasonryItem
            title='BlitzPrep Studios'
            short=''
            extraClass='col-lg-4 col-md-6 col-12'
            imageSrc={studios3} />

          <MasonryItem
            title='BlitzPrep Studios'
            short=''
            extraClass='col-lg-8 col-md-6 col-12'
            imageSrc={studios4} />

          <MasonryItem
            title='BlitzPrep Studios'
            short=''
            extraClass='col-lg-8 col-md-6 col-12'
            imageSrc={studios5} />

          <MasonryItem
            title='BlitzPrep Studios'
            short=''
            extraClass='col-lg-4 col-md-6 col-12'
            imageSrc={studios6} />

        </Masonry>
      </section>

      {!embed &&
      <section className="text-center bg-dark">
        <Anchor tag="form" />
        <br/>
        <h1 style={{color:'white'}}>This feature hasn't launched yet! But give us your feedback!</h1>
        <TypeformEmbed url={'https://blitzprep.typeform.com/to/A1NU6b'} style={{ width: '100%', height: '500px', paddingTop: '0' }} />
      </section>}
    </Fragment>);
  }
}

export default WithAuthorization(authCondition)(Studios);
