import { Component } from 'react';
import * as publicIp from 'public-ip';
import * as moment from 'moment';
import * as Sentry from '@sentry/browser';

import * as collections from 'constants/collections';

class Screen extends Component {
  trackActivity = async (info, nextProps = null) => {
    const { event, data = {} } = info;
    const { auth: { uid }, firestore } = (nextProps || this.props);
    if (!firestore) {
      const msg = `firestore came empty in ${this.constructor.name}`;
      if (process.env.NODE_ENV !== 'development') {
        Sentry.captureMessage(msg);
      } else {
        console.log(msg);
      }
      return;
    }

    const now = moment.utc();
    const dateTimeUTC = now.format("YYYY-MM-DDTHH:mm:ss.SSSSSSZ");
    const epochUTC = parseFloat(now.format("x")) / 1000;
    let ip = null;
    try {
      ip = (await publicIp.v4()) || (await publicIp.v6());
    } catch (err) {
    }
    const entry = { event, data, ip, epochUTC };
    if (uid) {
      entry.userId = uid;
    }
    if (process.env.NODE_ENV === 'development') {
      console.log('tracking activity...', entry);
    }
    firestore.set({ collection: collections.ACTIVITY, doc: dateTimeUTC }, entry);
  }
}

export default Screen;
