import React, { Component } from 'react';
import { TimelineItem } from '.';
import PropTypes from 'prop-types';

class Timeline extends Component {
  static propTypes = {
    title: PropTypes.string,
  };

  render() {
    const { title } = this.props;

    const children = this.props.children.filter(child => child.type === TimelineItem);
    return (
      <div className="col-lg-12">
        <div className="text-center">
          <h3>{title || '[Click to Expand Photos]'}</h3></div>
        <div className="process-1">
          {children}
        </div>
      </div>
    );
  }
}

export default Timeline;
