import React, { Fragment } from 'react';

import WithAuthorization from 'components/WithAuthorization';
import { authCondition, identifyMeOnTypeform } from 'utils';

import { Teaser } from 'components/Screen';
import LinkInner from 'components/LinkInner';
import Anchor from 'components/Anchor';
import TypeformEmbed from 'components/TypeformEmbed';

// Images
import coaching from 'images/coaching.jpg';
import coaching1 from 'images/coaching2.jpg';
import coaching2 from 'images/coaching3.jpg';
import coaching3 from 'images/coaching4.jpg';
import brian from 'images/brian_Avatar.jpg';

class Coaching extends Teaser {
  renderContent() {

    return (<Fragment>
      <section className="imagebg text-center" data-overlay="1" style={{backgroundColor: 'black'}}>
        <div className="container" style={{paddingTop: '30px'}}>
          <div className="row">
            <div className="col-md-12 ">
              <h1>BlitzPrep Coaching</h1>
              <div className="col-md-12 col-lg-12">
                <h4 style={{paddingBottom:'10px'}}>As an athlete you had an incredible support system built around you that consisted of: coaches, teammates, player personnel, health and wellness advisors, and so much more. BlitzPrep Coaching offers a system of support while empowering you to live a life of independence and freedom.</h4>
                <LinkInner title={'Get Started'} path={'#form'} style={{marginBottom: '30px'}}/>
                <img alt="Coaching" src={coaching1} style={{width:'100%', paddingBottom:'40px'}} />
                <h2>BlitzPrep Peer Coaches</h2>
                <h4 style={{paddingBottom:'40px'}}>BlitzPrep Peer Coaches are football players, just like you. They’re not licensed therapists, but they’ve made the big plays, hung up their cleats, and successfully transitioned to life after football. They’re not here to judge you because they’ve been you. All conversations are held as sacred as they were in the locker room.</h4>
                <LinkInner title={'Talk To A Peer Coach'} path={'#'} style={{marginBottom:'30px'}} />
                <img alt="Coaching" src={coaching} style={{width:'100%', paddingBottom:'40px'}} />
                <h2>BlitzPrep Success Coaches</h2>
                <div className="row">
                  <div className="col-md-12">
                  </div>
                  <div className="col-md-6" style={{marginRight:'90px'}}>
                    <h4 style={{paddingBottom:'40px', textAlign:'left'}}>BlitzPrep Success Coaches are long-time University academic and counselors as well as licensed mental health experts. In short, they’ve gone pro in athlete-wellness. Partnering with BlitzPrep Peer Coaches, Success Coaches are available to help with the stressors of leaving the game, assist and guide the development process of a career plan to help you be successful.</h4>
                  </div>
                  <div className="col-md-4">
                    <h4 style={{textAlign:'left', marginBottom:'5px'}}>Frequently discussed challenges:</h4>
                    <ul style={{textAlign:'left'}}>
                      <li><h5 style={{marginBottom:'-5px'}}>•	Lack of autonomy and agency</h5></li>
                      <li><h5 style={{marginBottom:'-5px'}}>•	Lack of purpose</h5></li>
                      <li><h5 style={{marginBottom:'-5px'}}>•	Lack of self-worth and self-value</h5></li>
                      <li><h5 style={{marginBottom:'-5px'}}>•	Financial crisis</h5></li>
                      <li><h5 style={{marginBottom:'-5px'}}>•	Loss of direction</h5></li>
                      <li><h5 style={{marginBottom:'-5px'}}>•	Mental, physical, spiritual, emotional pain</h5></li>
                      <li><h5 style={{marginBottom:'-5px'}}>•	Inauthentic relationships</h5></li>
                      <li><h5 style={{marginBottom:'-5px'}}>•	Marital strife and divorce</h5></li>
                      <li><h5 style={{marginBottom:'-5px'}}>•	Skills and education completion</h5></li>
                      <li><h5 style={{marginBottom:'-5px'}}>•	Career Planning</h5></li>
                    </ul>
                  </div>
                </div>
                <LinkInner title={'Talk To A Success Coach'} path={'#'} style={{marginBottom:'30px'}} />
                <img alt="Coaching" src={coaching2} style={{width:'100%', marginTop:'40px'}} />
                <img alt="Coaching" src={coaching3} style={{width:'100%'}} />
                <div className="testimonial row justify-content-center">
                  <div className="col-lg-7 col-md-8 col-12">
                    <span className="h3" style={{fontStyle:'italic'}}>&ldquo;I came in here one way, and I walked out totally different. Everyday just getting better. There’s limitless possibilities…this is some life changing shit.&rdquo;
                    </span>
                    <img className="testimonial__image" alt="Brian" src={brian} style={{width:'120px'}} />
                    <h5>Brian Price</h5>
                    <span>BlitzPrep Graduate</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="text-center bg-dark">
        <div className="container">
          <Anchor tag="form" />
          <h1 style={{color:'white'}}>This feature hasn't launched yet! But give us your feedback!</h1>
          <TypeformEmbed url={identifyMeOnTypeform(this.props, 'https://blitzprep.typeform.com/to/A1NU6b')} style={{ width: '100%', height: '500px', paddingTop: '0' }} />
        </div>
      </section>
    </Fragment>);
  }
}

export default WithAuthorization(authCondition)(Coaching);
