import React from 'react';
import _ from 'lodash';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect, isLoaded } from 'react-redux-firebase';
import { Helmet } from "react-helmet";

import WithAuthorization from 'components/WithAuthorization';
import { authCondition } from 'utils';
import Screen from 'components/Screen';
import Layout from 'components/Layout';
import Feature from 'components/Feature';
import Loading from 'components/Loading';
import BackgroundImage from 'components/BackgroundImage';
import LinkInner from 'components/LinkInner';
import Timeline, { TimelineItem} from 'components/Timeline';
import { episodePagePath } from 'constants/routes';
import { events } from 'constants/activity';
import * as collections from 'constants/collections';

import playLight from 'images/play-light.png';

class Episode extends Screen {
  async componentWillUpdate(nextProps) {
    const { profile, match } = nextProps;
    // Check if user's profile has been loaded
    if (isLoaded(profile)) {
      const { params: { episodeId } } = match;
      const lastPageId = profile.progress && profile.progress[episodeId] && profile.progress[episodeId].pageId;
      // Let's see where the user was
      if (!lastPageId && lastPageId !== 0) {
        const data = { episodeId };
        const event = events.EPISODE_STARTED;
        this.trackActivity({ event, data }, nextProps);
      } else {
        // TODO: Do we need to check for next episode if user has cover all previous episode content?
      }
    }
  }

  render() {
    if (!isLoaded(this.props.episode)) {
      return (<Loading />);
    }

    const { match: { url, params: { episodeId } }, episode, profile: { progress } } = this.props;
    const { features, content } = episode;
    const pageId = ((progress && progress[episodeId] && progress[episodeId].pageId) || 0) + 1;

    return (
      <Layout>
        <Helmet>
          <title>{episode.heading} | BlitzPrep University</title>
          <meta name="description" content={episode.short} />
        </Helmet>
        <section id="home" className="cover imagebg height-100 text-center parallax" data-overlay="4">
          <BackgroundImage source={episode.hero} parallax />
          <div className="container pos-vertical-center col-12 text-left" style={{ marginLeft: '-100px' }}>
            <div className="row">
              <div className="col-md-9 col-lg-8">
                <h1>{episode.heading}</h1>
                <p className="lead" style={{ paddingLeft: '5px', maxWidth: '600px' }}>{episode.description}</p>
                {content.length > 0 && <LinkInner title={progress && progress[episodeId] && progress[episodeId].pageId ? 'Continue' : 'Start Here'} to={`${url}/page-${pageId}`} icon={playLight} extraClass={'btn--primary'}/>}
                <LinkInner title={'More Info'} path={'#intro'} />
              </div>
            </div>
          </div>
        </section>
        <section id="intro">
          <div className="container">
            <div className="row">
              {
                features.map((item, idx) => (
                  <div className="col-md-4" key={idx}>
                    <Feature
                      type={item.type}
                      title={idx > 0 ? `${idx}. ${item.title}` : item.title}
                      content={item.content} />
                  </div>
                ))
              }
            </div>
          </div>
        </section>
        <section className=" ">
          <div className="container">
            <div className="row justify-content-center">
              <Timeline title={'[Click to Go]'}>
                {content.map((item, idx) => (
                  <TimelineItem key={idx}
                    title={item.heading}
                    imageSrc={item.thumbnail}
                    url={episodePagePath.replace(':episodeId', episodeId).replace(':pageId', (idx + 1))}
                    short={item.short}
                  />
                ))}
              </Timeline>
            </div>
          </div>
        </section>
        </Layout>
    );
  }
}

const FirestoreConnected = compose(
  firestoreConnect(({ match }) => [
    { collection: collections.EPISODE, doc: match.params.episodeId }
  ]),
  connect(({ firestore: { ordered }}) => ({
    episode: _.first(ordered.episodes || []),
  }))
)(Episode);
export default WithAuthorization(authCondition)(FirestoreConnected);
