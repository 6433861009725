import React, { Component } from 'react';
import WizardItem from './WizardItem';


class Wizard extends Component {
  render() {
    const children = this.props.children.filter(child => child.type === WizardItem);
    return (<div className="wizard">
      {children}
    </div>);
  }
}

export default Wizard;
