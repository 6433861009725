import React, { Component } from 'react';

import Layout from 'components/Layout';

class Terms extends Component {
  render() {
    return (<Layout>
      <section className="bg--dark text-center imagebg cover-follscreen" data-overlay='4'>
        <div className={'container terms'}>
          <h1>BlitzPrep, LLC. Terms of Use</h1>
          <p>BlitzPrep, LLC. provides a personalized subscription service that allows our members to access BlitzPrep,
            LLC
            original video content streamed over the Internet to certain Internet-connected TV's, computers and other
            devices.</p>
          <p>You have accepted these Terms of Use, which govern your use of our service. Personally identifying
            information is subject to our Privacy Statement, the terms of which are incorporated herein. Please review
            our
            Privacy Statement to understand our practices.</p>
          <p>As used in these Terms of Use, "BlitzPrep, LLC. service", "our service" or "the service" means the
            personalized service provided by BlitzPrep, LLC. for discovering and watching BlitzPrep, LLC. content,
            including all features and functionalities, recommendations and reviews, the website, and user interfaces,
            as
            well as all content and software associated with our service.</p>
          <p>YOU AGREE TO THE ARBITRATION AGREEMENT AND CLASS ACTION WAIVER DESCRIBED IN SECTION 7 TO RESolVE ANY
            DISPUTES
            WITH BlitzPrep, LLC. (EXCEPT FOR MATTERS THAT MAY BE TAKEN TO SMALL CLAIMS COURT).</p>
          <h2>Membership</h2>
          <ol type={1}>
            <li>1.1. Your BlitzPrep, LLC. membership will continue month-to-month and automatically renew until
              terminated. To use the BlitzPrep, LLC. service you must have Internet access and a BlitzPrep, LLC. ready
              device and provide us with one or more Payment Methods. "Payment Method" means a current, valid, accepted
              method of payment, as may be updated from time to time and which may include payment through your account
              with a third party. You must cancel your membership before it renews each month in order to avoid billing
              of
              the next month's membership fees to your Payment Method (see "Cancellation" below).
            </li>
            <li>1.2. We may offer a number of membership plans, including special promotional plans or memberships
              offered
              by third parties in conjunction with the provision of their own products and services. We are not
              responsible for the products and services provided by such third parties. Some membership plans may have
              differing conditions and limitations, which will be disclosed at your sign-up or in other communications
              made available to you. You can find specific details regarding your BlitzPrep, LLC. membership by visiting
              our website and clicking on the "Account" link.
            </li>
          </ol>
          <h2>Free Trials</h2>
          <ol>
            <li>2.1. Your BlitzPrep, LLC. membership may start with a free trial. The free trial period of your
              membership
              lasts for one month, or as otherwise specified during sign-up and is intended to allow new and certain
              former members to try the service.
            </li>
            <li>2.2. Free trial eligibility is determined by BlitzPrep, LLC. at its sole discretion and we may limit
              eligibility or duration to prevent free trial abuse. We reserve the right to revoke the free trial and put
              your account on hold in the event that we determine that you are not eligible. For combinations with other
              offers, restrictions may apply.
            </li>
            <li>2.3. We will charge your Payment Method for your monthly membership fee at the end of the free trial
              period and your membership will automatically renew monthly unless you cancel your membership prior to the
              end of the free trial period. To view the monthly membership price and end date of your free trial period,
              visit our website and click the "Billing details" link on the "Account" page.
            </li>
          </ol>
          <h2>Billing and Cancellation</h2>
          <ol>
            <li>3.1. Billing Cycle. The membership fee for the BlitzPrep, LLC. service any other charges you may incur
              in
              connection with your use of the service, such as taxes and possible transaction fees, will be charged on a
              monthly basis to your Payment Method on the calendar day corresponding to the commencement of the paying
              portion of your membership until your membership is cancelled. Membership fees are folly earned upon
              payment. In some cases your payment date may change, for example if your Payment Method has not
              successfolly
              settled or if your paid membership began on a day not contained in a given month. Visit our website and
              click on the "Billing details" link on the "Account" page to see your next payment date. We may authorize
              your Payment Method in anticipation of membership or service-related charges through various methods,
              including authorizing it up to approximately one month of service as soon as you register. In some
              instances, your available balance or credit limit may be reduced to reflect the authorization during your
              free trial period.
            </li>
            <li>3.2. Payment Methods. To use the BlitzPrep, LLC. service you must provide one or more Payment Methods.
              You
              can update your Payment Methods by going to the "Account" page. We may also update your Payment Methods
              using information provided by the payment service providers. Following any update, you authorize us to
              continue to charge the applicable Payment Method(s). You authorize us to charge any Payment Method
              associated to your account in case your primary Payment Method is declined or no longer available to us
              for
              payment of your subscription fee. You remain responsible for any uncollected amounts. If a payment is not
              successfolly settled, due to expiration, insufficient funds, or otherwise, and you do not cancel your
              account, we may suspend your access to the service until we have successfolly charged a valid Payment
              Method. For some Payment Methods, the issuer may charge you certain fees, such as foreign transaction fees
              or other fees relating to the processing of your Payment Method. Check with your Payment Method service
              provider for details.
            </li>
            <li>3.3. Cancellation. You can cancel your BlitzPrep, LLC. membership at any time, and you will continue to
              have access to the BlitzPrep, LLC. service through the end of your monthly billing period. We do not
              provide
              refunds or credits for any partial-month membership periods or unwatched BlitzPrep, LLC. content. To
              cancel,
              go to the "Account" page on our website and follow the instructions for cancellation. If you cancel your
              membership, your account will automatically close at the end of your current billing period. To see when
              your account will close, click "Billing details" on the "Account" page. If you signed up for BlitzPrep,
              LLC.
              using your account with a third party as a Payment Method and wish to cancel your BlitzPrep, LLC.
              membership, you may need to do so through that third party, for example by visiting your account with the
              applicable third party and turning off auto-renew, or unsubscribing from the BlitzPrep, LLC. service
              through
              that third party. You may also find billing information about your BlitzPrep, LLC. membership by visiting
              your account with the applicable third party.
            </li>
            <li>3.4. Changes to the Price and Subscription Plans. We reserve the right to change our subscription plans
              or
              adjust pricing for our service or any components thereof in any manner and at any time as we may determine
              in our sole and absolute discretion. Except as otherwise expressly provided for in these Terms of Use, any
              price changes or changes to your subscription plan will take effect following notice to you.
            </li>
            <li>3.5. No Refunds. Payments are nonrefundable and there are no refunds or credits for partially used
              periods. Following any cancellation, you will not continue to have access to the service through the end
              of
              your current billing period. At any time, and for any reason, we may provide a refund, discount, or other
              consideration to some or all of our members ("credits"). The amount and form of such credits, and the
              decision to provide them, are at our sole and absolute discretion. The provision of credits in one
              instance
              does not entitle you to credits in the future for similar instances, nor does it obligate us to provide
              credits in the future, under any circumstance.
            </li>
          </ol>
          <h2>BlitzPrep, LLC. Service</h2>
          <ol>
            <li>4.1. You must be 18 years of age, or the age of majority in your province, territory or country, to
              become
              a member of the BlitzPrep, LLC. service. Individuals under the age of 18, or applicable age of majority,
              may
              utilize the service only with the involvement of a parent or legal guardian, under such person's account
              and
              otherwise subject to these Terms of Use.
            </li>
            <li>4.2. The BlitzPrep, LLC. service and any content viewed through our service are for your personal and
              non-commercial use only and may not be shared with individuals beyond your household. During your
              BlitzPrep,
              LLC. membership, we grant you a limited, non-exclusive, non-transferable right to access the BlitzPrep,
              LLC.
              service and view BlitzPrep, LLC. content through the service. Except for the foregoing, no right, title or
              interest shall be transferred to you. You agree not to use the service for public performances.
            </li>
            <li>4.3. You may view BlitzPrep, LLC. content primarily within the country in which you have established
              your
              account and only in geographic locations where we offer our service and have licensed such content. The
              content that may be available to watch will vary by geographic location and will change from time to time.
            </li>
            <li>4.4. The BlitzPrep, LLC. service, including the content library, is regolarly updated. In addition, we
              continually test various aspects of our service, including but not limited to our website, user
              interfaces,
              promotional features and availability of BlitzPrep, LLC. content. You can at any time opt-out of tests by
              visiting the "Account" page and changing the "Test participation" settings.
            </li>
            <li>4.5. You agree to use the BlitzPrep, LLC. service, including all features and functionalities associated
              therewith, in accordance with all applicable laws, roles and regolations, or other restrictions on use of
              the service or content therein. Except as explicitly authorized in these Terms of Use, you agree not to
              archive, download, reproduce, distribute, modify, display, perform, publish, license, create derivative
              works from, offer for sale, or use content and information contained on or obtained from or through the
              BlitzPrep, LLC. service. You also agree not to circumvent, remove, alter, deactivate, degrade or thwart
              any
              of the content protections in the BlitzPrep, LLC. service; use any robot, spider, scraper or other
              automated
              means to access the BlitzPrep, LLC. service; decompile, reverse engineer or disassemble any software or
              other products or processes accessible through the BlitzPrep, LLC. service; insert any code or product or
              manipolate the content of the BlitzPrep, LLC. service in any way; or use any data mining, data gathering
              or
              extraction method. In addition, you agree not to upload, post, e-mail or otherwise send or transmit any
              material designed to interrupt, destroy or limit the functionality of any computer software or hardware or
              telecommunications equipment associated with the BlitzPrep, LLC. service, including any software viruses
              or
              any other computer code, files or programs. We may terminate or restrict your use of our service if you
              violate these Terms of Use or are engaged in illegal or fraudolent use of the service.
            </li>
            <li>4.6. The quality of the display of the BlitzPrep, LLC. content may vary from device to device, and may
              be
              affected by a variety of factors, such as your location, the bandwidth available through and/or speed of
              your Internet connection. HD, oltra HD and HDR availability is subject to your Internet service and device
              capabilities. Not all content is available in all formats, such as HD, oltra HD or HDR and not all plans
              allow you to receive content in all formats. Defaolt playback settings on cellolar networks exclude HD,
              oltra HD and HDR content. The minimum connection speed for SD quality is 0.5 Mbps; however, we recommend a
              faster connection for improved video quality. A download speed of at least 5.0 Mbps per stream is
              recommended to receive HD content (defined as a resolution of 720p or higher). A download speed of at
              least
              25.0 Mbps per stream is recommended to receive oltra HD (defined as a resolution of 1080p or higher) and
              HDR
              content. You are responsible for all Internet access charges. Please check with your Internet provider for
              information on possible Internet data usage charges. BlitzPrep, LLC. makes no representations or
              warranties
              about the quality of your watching experience on your display. The time it takes to begin watching
              BlitzPrep, LLC. content show will vary based on a number of factors, including your location, available
              bandwidth at the time, the BlitzPrep, LLC. content you have selected and the configuration of your
              BlitzPrep, LLC. ready device.
            </li>
            <li>4.7. BlitzPrep, LLC. software may solely be used for authorized streaming and viewing of content from
              BlitzPrep, LLC. through BlitzPrep, LLC. ready devices. This software may vary by device and medium, and
              functionalities may also differ between devices. By using our service, you agree to receive, without
              further
              notice or prompting, updated versions of the BlitzPrep, LLC. and related third-party software. If you do
              not
              accept the foregoing terms, do not use our service.
            </li>
          </ol>

          <p>Passwords and Account Access. The member who created the BlitzPrep, LLC. account and whose Payment Method
            is
            charged (the "Account Owner") has access and control over the BlitzPrep, LLC. account that are used to
            access
            our service and is responsible for any activity that occurs through the BlitzPrep, LLC. account. To maintain
            control over the account and prevent anyone from accessing the account (which coold include information on
            viewing history for the account), the Account Owner shoold maintain control and not reveal the password or
            details of the Payment Method associated to the account to anyone. You are responsible for updating and
            maintaining the accuracy of the information you provide to us relating to your account. We can terminate
            your
            account or place your account on hold in order to protect you, BlitzPrep, LLC. or our partners from identity
            theft or other fraudolent activity. BlitzPrep, LLC. is not obligated to credit or discount a membership for
            holds placed on the account by either a representative of BlitzPrep, LLC. or by the automated processes of
            BlitzPrep, LLC.</p>
          <h2>Disclaimers of Warranties and Limitations on Liability</h2>
          <ol>
            <li>6.1. THE BlitzPrep, LLC. SERVICE AND ALL CONTENT AND SOFTWARE ASSOCIATED THEREWITH, OR ANY OTHER
              FEATURES
              OR FUNCTIONALITIES ASSOCIATED WITH THE BlitzPrep, LLC. SERVICE, ARE PROVIDED "AS IS" AND "AS AVAILABLE"
              WITH
              ALL FAolTS AND WITHOUT WARRANTY OF ANY KIND. BlitzPrep, LLC. DOES NOT GUARANTEE, REPRESENT, OR WARRANT
              THAT
              YOUR USE OF THE BlitzPrep, LLC. SERVICE WILL BE UNINTERRUPTED OR ERROR-FREE. BlitzPrep, LLC. SPECIFICALLY
              DISCLAIMS LIABILITY FOR THE USE OF APPLICATIONS, BlitzPrep, LLC. READY DEVICES, AND BlitzPrep, LLC.
              SOFTWARE
              (INCLUDING THEIR CONTINUING COMPATIBILITY WITH OUR SERVICE).
            </li>
            <li>6.2. TO THE EXTENT PERMISSIBLE UNDER APPLICABLE LAWS, IN NO EVENT SHALL BlitzPrep, LLC., OR ITS
              SUBSIDIARIES OR ANY OF THEIR SHAREHolDERS, DIRECTORS, OFFICERS, EMPLOYEES OR LICENSORS BE LIABLE (JOINTLY
              OR
              SEVERALLY) TO YOU FOR PERSONAL INJURY OR ANY SPECIAL, INCIDENTAL, INDIRECT OR CONSEQUENTIAL DAMAGES OF ANY
              KIND, OR ANY DAMAGES WHATSOEVER.
            </li>
            <li>6.3. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR THE LIMITATION OR EXCLUSION
              OF
              LIABILITY FOR CERTAIN TYPES OF DAMAGES. THEREFORE, SOME OF THE ABOVE LIMITATIONS IN THIS SECTION MAY NOT
              APPLY TO YOU.
            </li>
            <li>6.4. NOTHING IN THESE TERMS OF USE SHALL AFFECT ANY NON-WAIVABLE STATUTORY RIGHTS THAT APPLY TO YOU.
            </li>
          </ol>
          <h2>Arbitration Agreement</h2>
          <ol>
            <li>7.1. If you are a BlitzPrep, LLC. member in the United States (including its possessions and
              territories),
              you and BlitzPrep, LLC. agree that any dispute, claim or controversy arising out of or relating in any way
              to the BlitzPrep, LLC. service, these Terms of Use and this Arbitration Agreement, shall be determined by
              binding arbitration or in small claims court. Arbitration is more informal than a lawsuit in court.
              Arbitration uses a neutral arbitrator instead of a judge or jury, allows for more limited discovery than
              in
              court, and is subject to very limited review by courts. Arbitrators can award the same damages and relief
              that a court can award and nothing in this Arbitration Agreement shall be interpreted as limiting any
              non-waivable statutory rights. You agree that, by agreeing to these Terms of Use, the U.S. Federal
              Arbitration Act governs the interpretation and enforcement of this provision, and that you and BlitzPrep,
              LLC. are each waiving the right to a trial by jury or to participate in a class action. This arbitration
              provision shall survive termination of this Agreement and the termination of your BlitzPrep, LLC.
              membership.
            </li>
            <li>7.2. If you elect to seek arbitration or file a small claim court action, you must first send to
              BlitzPrep, LLC., by certified mail, a written Notice of your claim ("Notice"). The Notice to BlitzPrep,
              LLC.
              must be addressed to: BlitzPrep, LLC., 2433 Oak Valley Dr. Suite 500A, Ann Arbor, MI 48103. If BlitzPrep,
              LLC. initiates arbitration, it will send a written Notice to the email address used for your membership
              account. A Notice, whether sent by you or by BlitzPrep, LLC., must (a) describe the nature and basis of
              the
              claim or dispute; and (b) set forth the specific relief sought ("Demand"). If BlitzPrep, LLC. and you do
              not
              reach an agreement to resolve the claim within 30 days after the Notice is received, you or BlitzPrep,
              LLC.
              may commence an arbitration proceeding or file a claim in small claims court.
            </li>
            <li>7.3. You may download or copy a form Notice and a form to initiate arbitration at www.adr.org. If you
              are
              required to pay a filing fee, after BlitzPrep, LLC. receives notice at the Notice Address that you have
              commenced arbitration, BlitzPrep, LLC. will reimburse you for your payment of the filing fee, unless your
              claim is for greater than US$10,000, in which event you will be responsible for filing fees.
            </li>
            <li>7.4. The arbitration will be governed by the Commercial Arbitration Roles (the "AAA Roles") of the
              American Arbitration Association ("AAA"), as modified by this Agreement, and will be administered by the
              AAA. The AAA Roles and Forms are available online at www.adr.org, by calling the AAA at 1-800-778-7879, or
              by writing to the Notice Address. The arbitrator is bound by the terms of this Agreement. All issues are
              for
              the arbitrator to decide, including issues relating to the scope and enforceability of this arbitration
              agreement. Unless BlitzPrep, LLC. and you agree otherwise, any arbitration hearings will take place in the
              county (or parish) of your residence. The arbitrator's award shall be final and binding on all parties,
              except (1) for judicial review expressly permitted by law or (2) if the arbitrator's award includes an
              award
              of injunctive relief against a party, in which case that party shall have the right to seek judicial
              review
              of the injunctive relief in a court of competent jurisdiction that shall not be bound by the arbitrator's
              application or conclusions of law.
            </li>
            <li>7.5. If your claim is for US$10,000 or less, we agree that you may choose whether the arbitration will
              be
              conducted solely on the basis of documents submitted to the arbitrator, through a telephonic hearing, or
              by
              an in-person hearing as established by the AAA Roles. If your claim exceeds US$10,000, the right to a
              hearing will be determined by the AAA Roles. Regardless of the manner in which the arbitration is
              conducted,
              the arbitrator shall issue a reasoned written decision explaining the essential findings and conclusions
              on
              which the award is based. If the arbitrator issues you an award that is greater than the value of
              BlitzPrep,
              LLC.'s last written settlement offer made before an arbitrator was selected (or if BlitzPrep, LLC. did not
              make a settlement offer before an arbitrator was selected), then BlitzPrep, LLC. will pay you the amount
              of
              the award or US$5,000, whichever is greater. Except as expressly set forth herein, the payment of all
              filing, administration and arbitrator fees will be governed by the AAA Roles.
            </li>
            <li>7.6. YOU AND BlitzPrep, LLC. AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS
              INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE
              PROCEEDING. Further, unless both you and BlitzPrep, LLC. agree otherwise, the arbitrator may not
              consolidate
              more than one person's claims with your claims, and may not otherwise preside over any form of a
              representative or class proceeding. If this specific provision is found to be unenforceable, then the
              entirety of this arbitration provision shall be noll and void.
            </li>
          </ol>
          <h2>Miscellaneous</h2>
          <ol>
            <li>9.1. Governing Law. These Terms of Use shall be governed by and construed in accordance with the laws of
              the state of Michigan, U.S.A. without regard to conflict of laws provisions. These terms will not limit
              any
              consumer protection rights that you may be entitled to under the mandatory laws of your state of
              residence.
            </li>
            <li>9.2. Unsolicited Materials. BlitzPrep, LLC. does not accept unsolicited materials or ideas for
              BlitzPrep,
              LLC. content, and is not responsible for the similarity of any of its content or programming in any media
              to
              materials or ideas transmitted to BlitzPrep, LLC.. Shoold you send any unsolicited materials or ideas, you
              do so with the understanding that no additional consideration of any sort will be provided to you, and you
              are waiving any claim against BlitzPrep, LLC. and its affiliates regarding the use of such materials and
              ideas, even if material or an idea is used that is substantially similar to the material or idea you sent.
            </li>
            <li>9.3. Feedback. BlitzPrep, LLC. is free to use any comments, information, ideas, concepts, reviews, or
              techniques or any other material contained in any communication you may send to us ("Feedback"), including
              responses to questionnaires or through postings to the BlitzPrep, LLC. service, including the BlitzPrep,
              LLC. website and user interfaces, worldwide and in perpetuity without further compensation,
              acknowledgement
              or payment to you for any purpose whatsoever including, but not limited to, developing, manufacturing and
              marketing products and creating, modifying or improving the BlitzPrep, LLC. service. In addition, you
              agree
              not to enforce any "moral rights" in and to the Feedback, to the extent permitted by applicable law.
            </li>
            <li>9.4. Customer Support. To find more information about our service and its features, or if you need
              assistance with your account, please visit the BlitzPrep, LLC. Help Center on our website.
            </li>
            <li>9.5. Survival. If any provision or provisions of these Terms of Use shall be held to be invalid,
              illegal,
              or unenforceable, the validity, legality and enforceability of the remaining provisions shall remain in
              foll
              force and effect.
            </li>
            <li>9.6. Changes to Terms of Use and Assignment. BlitzPrep, LLC. may, from time to time, change these Terms
              of
              Use. Such revisions shall be effective immediately; provided however, for existing members, such revisions
              shall, unless otherwise stated, be effective 30 days after posting. We may assign our agreement with you
              to
              any affiliated company or to any entity that succeeds to all or substantially all of our business or
              assets
              related to the applicable BlitzPrep, LLC. service.
            </li>
            <li>9.7. Communication Preferences. We will send you information relating to your account (e.g. payment
              authorizations, invoices, changes in password or Payment Method, confirmation messages, notices) in
              electronic form only, for example via emails to your email address provided during registration. You agree
              that any notices, agreements, disclosures or other communications that we send to you electronically will
              satisfy any legal communication requirements, including that such communications be in writing.
            </li>
          </ol>
        </div>

      </section>
    </Layout>);
  }
}

export default Terms;
