import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withFirebase } from 'react-redux-firebase';
import { Link } from "react-router-dom";
import _ from 'lodash';

import WithAuthorization from 'components/WithAuthorization';
import Layout from 'components/Layout';
import BackgroundImage from 'components/BackgroundImage';

import { signupPath, homePath } from 'constants/routes';
import { unAuthCondition } from 'utils';
import { actions as loginActions } from 'reducers/login';

import brian2 from 'images/brian2.jpg'

class Login extends Component {
  static propTypes = {
    email: PropTypes.string,
    onEmailChanged: PropTypes.func,
    onPasswordChanged: PropTypes.func
  };

  onSubmit = (event) => {
    const {
      email,
      password,
      firebase,
      onError,
      clear,
    } = this.props;

    // email and password are not empty
    if (!(_.isEmpty(email) || _.isEmpty(password))) {
      firebase.login({ email, password });
      clear();
    } else {
      onError({
        message: 'Email and Password are required',
      });
    }

    event.preventDefault();
  }

  render() {
    // TODO: Display `error`
    const { email, password, onEmailChanged, onPasswordChanged } = this.props;

    return (
      <Layout>
        <section className="height-100 imagebg text-center" data-overlay="5">
          <BackgroundImage source={brian2} />
          <div className="container pos-vertical-center">
            <div className="row">
              <div className="col-md-7 col-lg-5">
                <h1>Login to continue</h1>
                <p className="lead">
                  Sign in with your existing BlitzPrep account credentials.
                </p>

                <div id="login_div" className="main-div">
                  <form onSubmit={this.onSubmit}>
                    <input
                      type="email"
                      value={email}
                      onChange={event => onEmailChanged(event.target.value) }
                      placeholder="Email..."
                      autoComplete="email"
                      id="email_field" />
                    
                    <input
                      type="password"
                      value={password}
                      onChange={event => onPasswordChanged(event.target.value) }
                      placeholder="Password..."
                      autoComplete="current-password"
                      id="password_field" />

								    <button
                      className="btn btn--primary type--uppercase loginButton"
                      type="submit"
                      >Login to Account</button>
                  </form>
                </div>
                <span className="type--fine-print block">Don't have an account yet? <Link to={signupPath}>Create account</Link></span>
                {/* TODO: Reset password */}
                {/* <span className="type--fine-print block">Forgot your username or password? <a>Recover account</a></span> */}
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

const mapStateToProps = state => ({
  ...state.login,
});
const mapDispatchToProps = ({
  ...loginActions,
});

const WithFirebase = withFirebase(connect(mapStateToProps, mapDispatchToProps)(Login));
export default WithAuthorization(unAuthCondition, homePath)(WithFirebase);
