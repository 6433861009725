import React, { Fragment } from 'react';

import WithAuthorization from 'components/WithAuthorization';
import { authCondition, identifyMeOnTypeform } from 'utils';

import { Teaser } from 'components/Screen';
import LinkInner from 'components/LinkInner';
import Anchor from 'components/Anchor';
import Masonry, { MasonryItem } from 'components/Masonry';
import TypeformEmbed from 'components/TypeformEmbed';

// Images
import accelerator1 from 'images/accelerator1.jpg';
import accelerator2 from 'images/accelerator2.jpg';
import accelerator3 from 'images/accelerator3.jpg';
import accelerator4 from 'images/accelerator4.jpg';
import accelerator5 from 'images/accelerator5.jpg';

class Accelerator extends Teaser {
  renderContent() {
    return (<Fragment>
      <section className="imagebg text-center" data-overlay="1" style={{backgroundColor: 'black'}}>
        <div className="container" style={{paddingTop: '30px'}}>
          <div className="row">
            <div className="col-md-12">
              <h1>BlitzPrep Accelerator</h1>
              <div className="col-md-12 col-lg-12">
                <h4 style={{paddingBottom:'10px'}}>The Accelerator Program opens the door for individuals who want to take their idea, product, or business to the next level.  We connect you with venture capitalists, angel investors, and business mentors to help get your idea off the ground.<br />Think:  Shark Tank meets Ballers!</h4>
                <LinkInner title={'Get Started'} path={'#form'} style={{marginBottom:'30px'}} />
                <img alt="Jack On Stage" src={accelerator1} style={{width:'100%', paddingBottom:'40px'}}/>
                <h2>The BlitzPrep Accelerator Team</h2>
                <h4 style={{paddingBottom:'0px'}}>Our team consists of successful business leaders and entrepreneurs who have decades of experience in catapulting ideas into real businesses. Our touchpoints include: investors, multimillion-dollar real estate companies, blockbuster movies, tech startups, published authors, international influencers, viral content creators, and so much more.</h4>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="unpad bg--dark" style={{ paddingBottom: '75px', paddingTop: '0', backgroundColor: 'black' }}>
        <Masonry extraClass="col-11">
          <MasonryItem
            title='Victor Hobson'
            short='Former NFL Linebacker'
            extraClass='col-lg-4 col-md-6 col-12'
            imageSrc={accelerator2} />

          <MasonryItem
            title='Shari Acho and Michael Nisson'
            short='BlitzPrep Founders'
            extraClass='col-lg-8 col-md-6 col-12'
            imageSrc={accelerator3} />

          <MasonryItem
            title='Calvin Gee'
            short='BlitzPrep Accelerator Partner'
            extraClass='col-lg-8 col-md-6 col-12'
            imageSrc={accelerator4} />

          <MasonryItem
            title='Ryan Doyle'
            short='BlitzPrep Accelerator Partner'
            extraClass='col-lg-4 col-md-6 col-12'
            imageSrc={accelerator5} />
        </Masonry>
      </section>

      <section className="text-center">
        <div className="container">
          <Anchor tag="form" />
          <h1>This feature has not launched yet! Please tell us what you think below!</h1>
          <TypeformEmbed url={identifyMeOnTypeform(this.props, 'https://blitzprep.typeform.com/to/KyqB52')} style={{ width: '100%', height: '500px', paddingTop: '0' }} />
        </div>
      </section>
    </Fragment>);
  }
}


export default WithAuthorization(authCondition)(Accelerator);
