import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import * as moment from 'moment';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect, isLoaded } from 'react-redux-firebase';

import WithAuthorization from 'components/WithAuthorization';
import Layout, { Content, Footer } from 'components/Layout';
import BackgroundImage from 'components/BackgroundImage';
import LinkInner from 'components/LinkInner';
import Loading from 'components/Loading';
import Slider, { SliderItem } from 'components/Slider';
import Video from 'components/Video';
import { episodePath, episodePagePath } from 'constants/routes';
import { COMING_SOON } from 'constants/status';
import * as collections from 'constants/collections';
import { authCondition } from 'utils';
import { homePath, browsePath, coachingPath, eventsPath, podcastsPath, jobsPath, acceleratorPath } from 'constants/routes';

import hero1 from 'images/hero-1.jpg'
import playLight from 'images/play-light.png';

class Browse extends Component {
  getLastSeason() {
    const { seasons, episodes } = this.props;
    const season = { ..._.last(seasons || []) };
    if (season) {
      season.episode = _.first(episodes.filter(e => e.seasonId === season.id));
    }

    return season;
  }

  renderMoreThan() {
    const sliderItems = [
      {
        to: '#',
        heading: '#MORETHAN | Sharing The Stage With Lil Wayne',
        subheading: 'Jack Kennedy',
        description: '<i class="material-icons">lock</i>',
        image: 'https://firebasestorage.googleapis.com/v0/b/blitzprep-university.appspot.com/o/posts%2Fjack.jpg?alt=media&token=8e5857b4-5fb7-4db1-98aa-61c588013539',
      },
      {
        to: '#',
        heading: '#MORETHAN | Forgiving Mom',
        subheading: 'Gerald Holmes',
        description: '<i class="material-icons">lock</i>',
        image: 'https://firebasestorage.googleapis.com/v0/b/blitzprep-university.appspot.com/o/posts%2Fmorethangerald.jpg?alt=media&token=b94083b3-fb8d-453d-87b1-7db7bae6ca0c',
      },
      {
        to: '#',
        heading: '#MORETHAN | White Tiger 2.0',
        subheading: 'Casey Therriault',
        description: '<i class="material-icons">lock</i>',
        image: 'https://firebasestorage.googleapis.com/v0/b/blitzprep-university.appspot.com/o/posts%2Fmorethancasey.jpg?alt=media&token=e02e7207-72c4-44bf-881a-f9f8be85c8de',
      }
    ];
    return (<section className="bg--dark" style={{ paddingTop: '0px', backgroundColor: 'black' }}>
      <div className="container col-12">
        <div className="row">
          <div className="col">
            <h4 style={{ color: 'white', marginTop: '-50px', marginLeft: '20px' }}>#MORETHAN</h4>
            <p style={{ marginLeft: '20px' }}>
              Authentic, real, and important stories of the ups and downs of being a Football Player. Our flagship series of player interviews that explores their intimate unknowns off the field. Because at BlitzPrep, we know you are “More Than” just an athlete.
            </p>
            <Slider paging={false} dataTiming={99999999999}>
              {sliderItems.map((i, idx) => (<SliderItem key={idx}
                to={i.to}
                heading={i.heading}
                subheading={i.subheading}
                description={i.description}
                grayOut={true}
                image={i.image} />))}
            </Slider>
          </div>
        </div>
      </div>
    </section>);
  }

  renderRealTalk() {
    const sliderItems = [
      {
        to: '#',
        heading: 'Divorcing the Game',
        subheading: 'Victor Hobson',
        description: '<i class="material-icons">lock</i>',
        image: 'https://firebasestorage.googleapis.com/v0/b/blitzprep-university.appspot.com/o/posts%2Fdivorce.jpg?alt=media&token=899820eb-2496-4c4a-beac-cfb6c1f802a2',
      },
      {
        to: '#',
        heading: 'Blind Spots',
        subheading: 'Victor Hobson',
        description: '<i class="material-icons">lock</i>',
        image: 'https://firebasestorage.googleapis.com/v0/b/blitzprep-university.appspot.com/o/posts%2Fblindspots.jpg?alt=media&token=271736e8-6985-43b2-bad2-6741a89575d7',
      },
      {
        to: '#',
        heading: 'Environmental Influences',
        subheading: 'Ryan Van Dyke',
        description: '<i class="material-icons">lock</i>',
        image: 'https://firebasestorage.googleapis.com/v0/b/blitzprep-university.appspot.com/o/posts%2Fnature.jpg?alt=media&token=65f49169-1766-47fa-8b9b-d30b91b97f40',
      },
      {
        to: '#',
        heading: 'Uniqueness of Me',
        subheading: 'Mike Martin',
        description: '<i class="material-icons">lock</i>',
        image: 'https://firebasestorage.googleapis.com/v0/b/blitzprep-university.appspot.com/o/posts%2Funique.jpg?alt=media&token=32e0b0a5-8dc0-4636-adaf-5d5a7865ac1a',
      },
      {
        to: '#',
        heading: 'Negotiating Contracts',
        subheading: 'Julius Curry',
        description: '<i class="material-icons">lock</i>',
        image: 'https://firebasestorage.googleapis.com/v0/b/blitzprep-university.appspot.com/o/posts%2Frealtalk_contracts.jpg?alt=media&token=98827f34-6f78-4f02-91ee-ef84530bd264',
      }
    ];
    return (<section className="bg--dark" style={{ paddingTop: '0px', backgroundColor: 'black' }}>
      <div className="container col-12">
        <div className="row">
          <div className="col">
            <h4 style={{ color: 'white', marginTop: '-50px', marginLeft: '20px' }}>#RealTalk</h4>
            <p style={{ marginLeft: '20px' }}>
              A series that features former players discussing specific BlitzPrep University curriculum topics through their own personal experiences. The students of the game have become the teachers.
            </p>
            <Slider paging>
              {sliderItems.map((i, idx) => (<SliderItem key={idx}
                to={i.to}
                heading={i.heading}
                subheading={i.subheading}
                description={i.description}
                grayOut={true}
                image={i.image} />))}
            </Slider>
          </div>
        </div>
      </div>
    </section>);
  }

  render() {
    if (!isLoaded(this.props.seasons) || !isLoaded(this.props.episodes)) {
      return (<Loading />);
    }

    const season = this.getLastSeason();
    // This could be the last watched episode
    const { episode } = season;
    // This could be the last watched content/page
    const content = _.first(episode.content || []);
    const { episodes, profile: { progress } } = this.props;
    const episodeId = (progress && progress.episodeId) || episode.id;
    const pageId = ((progress && progress[episodeId] && progress[episodeId].pageId) || 0) + 1;

    return (
      <Layout>
        <Content>
          <section className="cover imagebg videobg height-100 text-center" data-overlay="2" style={{ zIndex: 0 }}>
            {season && season.trailer && <Video source={season.trailer} autoPlay loop muted />}
            <BackgroundImage source={hero1} />
            <div className="container pos-vertical-center col-12 text-left" style={{ marginLeft: '-220px' }}>
              <div className="row">
                <div className="col-md-9 col-lg-6">
                  <h1>BlitzPrep University: {season.heading || `Season ${season.id}`}</h1>
                  <p className="lead" style={{ paddingLeft: '5px' }}>{season.description}</p>
                  {content && <LinkInner title={progress && progress[episodeId] && progress[episodeId].pageId ? 'Continue' : 'Start Here'}
                    icon={playLight}
                    extraClass={'btn--primary'}
                    to={episodePagePath.replace(':episodeId', episodeId).replace(':pageId', pageId)} />}
                </div>
              </div>
            </div>
          </section>
          <section className="bg--dark" style={{ marginTop: '-100px', paddingTop: '0px', backgroundColor: 'black' }}>
            <div className="container col-12">
              <div className="row">
                <div className="col">
                  <h4 style={{ color: 'white', marginTop: '-50px', marginLeft: '20px' }}>BlitzPrep University Episodes</h4>
                  <Slider paging>
                    {episodes.map((episode, idx) => {
                      let [heading, subheading] = episode.heading.split(':');
                      let description = null;
                      let grayOut = false;
                      if (episode.status === COMING_SOON) {
                        grayOut = true;
                        if (episode.releaseDate) {
                          description = `Coming ${moment(episode.releaseDate, 'MM/DD/YYYY').format("MMMM YYYY")}`;
                        }
                      }
                      return (<SliderItem
                        key={idx}
                        to={grayOut ? '#' : episodePath.replace(':episodeId', 1)}
                        heading={heading}
                        subheading={subheading}
                        description={description}
                        grayOut={grayOut}
                        image={episode.hero} />);
                    })}
                  </Slider>
                </div>
              </div>
            </div>
          </section>
          {this.renderMoreThan()}
          {this.renderRealTalk()}
          {/* We want the footer inside the content */}
          <Footer>
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="heading-block">
                    <ul className="list-inline list--hover">
                      <li>
                        <Link to={homePath}>
                          <span>Home</span>
                        </Link>
                      </li>
                      <li>
                        <Link to={browsePath}>
                          <span>University</span>
                        </Link>
                      </li>
                      <li>
                        <Link to={coachingPath}>
                          <span>Coaching</span>
                        </Link>
                      </li>
                      <li>
                        <Link to={eventsPath}>
                          <span>Events</span>
                        </Link>
                      </li>
                      <li>
                        <Link to={podcastsPath}>
                          <span>Podcast</span>
                        </Link>
                      </li>
                      <li>
                        <Link to={jobsPath}>
                          <span>Job Network</span>
                        </Link>
                      </li>
                      <li>
                        <Link to={acceleratorPath}>
                          <span>Accelerator</span>
                        </Link>
                      </li>
                      <li>
                        <Link to={'#'}>
                          <span>Help</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                  {/* <div>
                    <ul className="social-list list-inline list--hover">
                      <li>
                        <Link to={'#'}>
                          <i className="socicon socicon-google icon icon--xs"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to={'#'}>
                          <i className="socicon socicon-twitter icon icon--xs"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to={'#'}>
                          <i className="socicon socicon-facebook icon icon--xs"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to={'#'}>
                          <i className="socicon socicon-instagram icon icon--xs"></i>
                        </Link>
                      </li>
                    </ul>
                  </div> */}
                  <div>
                    <span className="type--fine-print">&copy; <span className="update-year"></span> BlitzPrep LLC.</span>
                    <Link to={'#'} className="type--fine-print">Privacy Policy</Link>
                    <Link to={'#'} className="type--fine-print">Legal</Link>
                  </div>
                </div>
              </div>
            </div>
          </Footer>
        </Content>
      </Layout>
    );
  }
}

const FirestoreConnected = compose(
  firestoreConnect([collections.SEASON, collections.EPISODE]),
  connect((state) => ({
    seasons: state.firestore.ordered.seasons,
    episodes: state.firestore.ordered.episodes,
  }))
)(Browse);
export default WithAuthorization(authCondition)(FirestoreConnected);
