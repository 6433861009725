import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { isLoaded } from 'react-redux-firebase';

import WithAuthorization from 'components/WithAuthorization';
import { authCondition, preSurveyCondition } from 'utils';
import { browsePath, preSurveyPath } from 'constants/routes';

import Layout from 'components/Layout';
import BackgroundImage from 'components/BackgroundImage';
import Loading from 'components/Loading';
import bpLanding from 'images/bp-landing.jpg';
import { Navigation, Header } from 'components/Layout';

class Home extends Component {
  render() {
    const { profile, profile: { displayName, firstName } } = this.props;
    if (!isLoaded(profile)) {
      return <Loading />;
    }

    const name = firstName || (displayName || '').split(' ')[0];

    return (
      <Layout>
        <Header><Navigation/></Header>
        <section className="height-100 imagebg text-center" data-overlay="1">
          <BackgroundImage source={bpLanding} />

          <div className="container pos-vertical-center">
            <div className="row">
              <div className="col-md-12">
                <h1>Welcome to BlitzPrep{name ? `, ${name}` : ''}.</h1>
                <Link to={browsePath} className="btn btn--primary type--uppercase">
                  <span className="btn__text">Start Here</span>
                </Link>
              </div>
            </div>
          </div>

        </section>
      </Layout>
    );
  }
}

export default WithAuthorization([
  { condition: authCondition },
  { condition: preSurveyCondition, path: preSurveyPath }
])(Home);
