// const string for actions in uppercase
const SIGNUP_PASSWORD_CHANGED = 'SIGNUP_PASSWORD_CHANGED';
const SIGNUP_REPASSWORD_CHANGED = 'SIGNUP_REPASSWORD_CHANGED';
const SIGNUP_FIRSTNAME_CHANGED = 'SIGNUP_FIRSTNAME_CHANGED';
const SIGNUP_LASTNAME_CHANGED = 'SIGNUP_LASTNAME_CHANGED';
const SIGNUP_CLEAR = 'SIGNUP_CLEAR';
const SIGNUP_ERROR = 'SIGNUP_ERROR';

// actions
export const actions = {
  onPasswordChanged: (password) => ({ type: SIGNUP_PASSWORD_CHANGED, password }),
  onRePasswordChanged: (rePassword) => ({ type: SIGNUP_REPASSWORD_CHANGED, rePassword }),
  onFirstNameChanged: (firstName) => ({ type: SIGNUP_FIRSTNAME_CHANGED, firstName }),
  onLastNameChanged: (lastName) => ({ type: SIGNUP_LASTNAME_CHANGED, lastName }),
  onError: (error) => ({ type: SIGNUP_ERROR, error }),
  clear: () => ({ type: SIGNUP_CLEAR }),
};

// initial state
const initialState = {
  password: '',
  rePassword: '',
  firstName: '',
  lastName: '',
  error: null
};

// action handlers
const actionHandlers = {
  [SIGNUP_PASSWORD_CHANGED]: (state, { password }) => ({ ...state, password }),
  [SIGNUP_REPASSWORD_CHANGED]: (state, { rePassword }) => ({ ...state, rePassword }),
  [SIGNUP_FIRSTNAME_CHANGED]: (state, { firstName }) => ({ ...state, firstName }),
  [SIGNUP_LASTNAME_CHANGED]: (state, { lastName }) => ({ ...state, lastName }),
  [SIGNUP_CLEAR]: (state) => ({ ...state, password: '', rePassword: '', firstName: '', lastName: '', error: null }),
  [SIGNUP_ERROR]: (state, { error }) => ({ ...state, error: error })
};

export const reducer = (state = initialState, action) => {
  const handler = actionHandlers[action.type]

  return handler ? handler(state, action) : state
};
