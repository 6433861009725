import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';


class WizardItem extends Component {
  static propTypes = {
    title: PropTypes.string,
    content: PropTypes.string,
    url: PropTypes.string,
  }

  render() {
    const { title, content, url } = this.props;
    return (<Fragment>
      <h5 style={{ color: 'white' }}>{title}</h5>
      <section className="text-center">
        <div className="pos-vertical-center">
          <h3>{content}</h3>
          <img alt={title} src={url} />
        </div>
      </section>
    </Fragment>);
  }
}

export default WizardItem;
