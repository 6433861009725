// const string for actions in uppercase
const PAYMENT_NAME_CHANGED = 'PAYMENT_NAME_CHANGED';
const PAYMENT_EMAIL_CHANGED = 'PAYMENT_EMAIL_CHANGED';
const PAYMENT_PHONE_CHANGED = 'PAYMENT_PHONE_CHANGED';
const PAYMENT_CARDNUMBER_CHANGED = 'PAYMENT_CARDNUMBER_CHANGED';
const PAYMENT_EXPIRY_CHANGED = 'PAYMENT_EXPIRY_CHANGED';
const PAYMENT_CVC_CHANGED = 'PAYMENT_CVC_CHANGED';
const PAYMENT_POSTALCODE_CHANGED = 'PAYMENT_POSTALCODE_CHANGED';
const PAYMENT_FOCUS_CHANGED = 'PAYMENT_FOCUS_CHANGED';
const PAYMENT_SUBMIT = 'PAYMENT_SUBMIT';

// actions
export const actions = {
  onNameChanged: (name) => ({ type: PAYMENT_NAME_CHANGED, name }),
  onEmailChanged: (email) => ({ type: PAYMENT_EMAIL_CHANGED, email }),
  onPhoneChanged: (phone) => ({ type: PAYMENT_PHONE_CHANGED, phone }),
  onCardNumberChanged: (cardNumber, issuer) => ({ type: PAYMENT_CARDNUMBER_CHANGED, cardNumber, issuer }),
  onExpiryChanged: (expiry) => ({ type: PAYMENT_EXPIRY_CHANGED, expiry }),
  onCVCChanged: (cvc) => ({ type: PAYMENT_CVC_CHANGED, cvc }),
  onPostalCodeChanged: (postalCode) => ({ type: PAYMENT_CVC_CHANGED, postalCode }),
  onFocusChanged: (focused) => ({ type: PAYMENT_FOCUS_CHANGED, focused }),
  onSubmit: () => ({ type: PAYMENT_SUBMIT }),
};

// initial state
const initialState = {
  name: '',
  email: '',
  phone: '',
  cardNumber: '',
  postalCode: '',
  issuer: '',
  expiry: '',
  cvc: '',
  focused: '',
  submitted: false
};

// action handlers
const actionHandlers = {
  [PAYMENT_NAME_CHANGED]: (state, { name }) => ({ ...state, name }),
  [PAYMENT_EMAIL_CHANGED]: (state, { email }) => ({ ...state, email }),
  [PAYMENT_PHONE_CHANGED]: (state, { phone }) => ({ ...state, phone }),
  [PAYMENT_CARDNUMBER_CHANGED]: (state, { cardNumber, issuer }) => ({ ...state, cardNumber, issuer }),
  [PAYMENT_EXPIRY_CHANGED]: (state, { expiry }) => ({ ...state, expiry }),
  [PAYMENT_CVC_CHANGED]: (state, { cvc }) => ({ ...state, cvc }),
  [PAYMENT_POSTALCODE_CHANGED]: (state, { postalCode }) => ({ ...state, postalCode }),
  [PAYMENT_FOCUS_CHANGED]: (state, { focused }) => ({ ...state, focused }),
  [PAYMENT_SUBMIT]: (state) => ({ ...state, submitted: true }),
};

export const reducer = (state = initialState, action) => {
  const handler = actionHandlers[action.type]

  return handler ? handler(state, action) : state
};
