export const homePath = '/';
export const browsePath = '/browse';
export const episodesPath = '/episodes';
export const episodePath = '/episode-:episodeId';
export const episodePagePath = '/episode-:episodeId/page-:pageId';
export const episodeSuccessPath = '/episode-:episodeId/success';
export const loginPath = '/login';
export const signupPath = '/signup';
export const paymentPath = '/payment';
export const preSurveyPath = '/pre-survey';
export const coachingPath = '/coaching';
export const eventsPath = '/events';
export const podcastsPath = '/podcasts';
export const pricingPath = '/pricing';
export const jobsPath = '/jobs';
export const acceleratorPath = '/accelerator';
export const overviewPath = '/overview';
export const shariPath = '/shari';
export const communityPath = '/community';
export const termsPath = '/terms';
export const postSurveyPath = '/post-survey';
export const toddDuckettPath = '/todd-duckett';
export const studiosPath = '/studios';
