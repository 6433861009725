import React, { Fragment } from 'react';

import WithAuthorization from 'components/WithAuthorization';
import { authCondition, identifyMeOnTypeform } from 'utils';

import { Teaser } from 'components/Screen';
import LinkNormal from 'components/LinkNormal';
import LinkInner from 'components/LinkInner';
import Anchor from 'components/Anchor';
import TypeformEmbed from 'components/TypeformEmbed';

// Images
import events from 'images/events.jpg';
import events1 from 'images/events1.jpg';
import events2 from 'images/events2.jpg';
import events3 from 'images/events3.jpg';

class Events extends Teaser {
  renderContent() {
    return (<Fragment>
      <section className="imagebg text-center" data-overlay="1" style={{backgroundColor: 'black'}}>
        <div className="container" style={{paddingTop: '30px'}}>
          <div className="row">
            <div className="col-md-12">
              <h1>BlitzPrep Events</h1>
              <div className="col-md-12 col-lg-12">
                <h4 style={{paddingBottom:'10px'}}>BlitzPrep isn’t just an online transformation, it is a robust suite of services to create new and exciting opportunities.</h4>
                <LinkInner title={'Get Started'} path={'#form'} style={{marginBottom:'30px'}} />
                <img alt="Events" src={events} style={{width:'100%', paddingBottom:'100px'}}/>
                <h2>Types of Events</h2>
                <h4 style={{paddingBottom:'0px'}}>
                  BlitzPrep hosts a wide range of events that bring together players, mentors, influencers, experts, friends, and family.<br></br>YOU don't have to make the journey alone, we're all in this together.
                </h4>
                <div className="row" style={{paddingBottom:'30px'}}>
                  <div className="col-md-12">
                    <ul style={{textAlign:'center'}}>
                      <li><h5>•	Athlete social gatherings (monthly)</h5></li>
                      <li><h5>•	Networking events with business leaders and influencers</h5></li>
                      <li><h5>•	Personal and Professional development sessions and workshops</h5></li>
                      <li><h5>•	Public speaking opportunities</h5></li>
                      <li><h5>•	Family, friends, and couples events</h5></li>
                      <li><h5>•	Events for all ages</h5></li>
                    </ul>
                  </div>
                </div>

                <LinkInner title={'Get Started'} path={'#form'} style={{marginBottom:'30px'}} />

                <img alt="Golf Outing" src={events1} style={{width:'100%', paddingBottom:'40px'}}/>
                <h2>BlitzPrep Jr.</h2>
                <h4 style={{paddingBottom:'20px'}}>
                BlitzPrep Jr. is designed to help the up-and-coming middle school and high school athletes and their families prepare for college and professional sports.
                Most uniquely, we focus on the <span style={{fontStyle:'italic'}}>human being</span> not just the <span style={{fontStyle:'italic'}}>athlete doing</span>.
                We focus on how to assure your student-athlete is fully prepared for being seen, being heard, and being prepared for life on and off the field.
                </h4>
                <LinkNormal to={'#'} className="btn btn--lg" style={{marginBottom:'30px'}}>
                  <span className="btn__text">Bring Us To Your School</span>
                </LinkNormal>
                <img alt="Big House" src={events2} style={{width:'100%', paddingBottom:'40px'}}/>
                <h2>BlitzFest</h2>
                <h4 style={{paddingBottom:'20px'}}>
                  BlitzPrep’s marquee event, BlitzFest will be the annual celebration of a year of hard work!  Unrivaled speakers, transformational activities and networking opportunities are all available to you as a BlitzPrep Member!
                </h4>
                <LinkNormal to={'#'} className="btn btn--lg" style={{marginBottom:'30px'}}>
                  <span className="btn__text">Become A Member</span>
                </LinkNormal>
                <img alt="BlitzFest" src={events3} style={{width:'100%'}}/>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="text-center">
        <div className="container">
          <Anchor tag="form" />
          <h1>This feature has not launched yet! Please tell us what you think below!</h1>
          <TypeformEmbed url={identifyMeOnTypeform(this.props, 'https://blitzprep.typeform.com/to/EvdDtL')} style={{ width: '100%', height: '500px', paddingTop: '0' }} />
        </div>
      </section>
    </Fragment>);
  }
}

export default WithAuthorization(authCondition)(Events);
