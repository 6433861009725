import React, { Component } from 'react';
import { Experiment, Variant, emitter } from '@marvelapp/react-ab-test';

import WithAuthorization from 'components/WithAuthorization';
import { authCondition } from 'utils';

import Layout, { Navigation, Header } from 'components/Layout';
import BackgroundImage from 'components/BackgroundImage';
import { PricingA, PricingB } from '.';
import { abTesting, site } from 'config';

import brian1 from 'images/brian.jpg';

class Pricing extends Component {
  constructor(props) {
    super(props);
    if (abTesting.enabled) {
      this.subscribe = emitter.addPlayListener(function(experimentName, variantName) {
        const virtualPageUrl = `${site.url}/pricing-${variantName.toLowerCase()}`;
        console.log('virtualPageUrl', virtualPageUrl);
        window.VWO = window.VWO || [];
        window.VWO.push(['activate', {
            virtualPageUrl
        }]);
      });
    }
  }

  componentWillUnmount() {
    abTesting.enabled && this.subscribe.remove();
  }

  render() {
    return (
      <Layout>
        <Header><Navigation/></Header>
        <section className="cover height-60 imagebg text-center" data-overlay="5">
            <BackgroundImage source={brian1} />
           <div className="container pos-vertical-center">
               <div className="row">
                   <div className="col-md-12">
                       <h1>
                           Your <strong>transformation</strong> begins with a <em>single</em> step.
                       </h1>
                   </div>
               </div>
           </div>
        </section>
        <Experiment name="Pricing">
            <Variant name="A">
                <PricingA />
            </Variant>
            <Variant name="B">
                <PricingB />
            </Variant>
        </Experiment>
      </Layout>
    );
  }
}

export default WithAuthorization(authCondition)(Pricing);
