import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Content extends Component {
  static propTypes = {
    mainClass: PropTypes.string,
  };

  render() {
    const { mainClass } = this.props;
    // You can put the footer in the content too :)
    return <div className={`main-container ${mainClass || ''}`}>
      {this.props.children}
    </div>;
  }
}

export default Content;
