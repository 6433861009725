import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

import { firebase as config } from '../config/index';

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

const auth = firebase.auth();
const firestore = firebase.firestore();
const settings = { timestampsInSnapshots: true };
firestore.settings(settings);

export {
  auth,
  firestore,
};

export default firebase;
