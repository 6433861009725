import React, { Component } from 'react';
import { withFirebase, isLoaded } from 'react-redux-firebase';
import { enhance } from 'utils';
import LinkNormal from 'components/LinkNormal';
import { Link } from 'react-router-dom';
import { homePath, browsePath, coachingPath, eventsPath, podcastsPath, jobsPath, acceleratorPath } from 'constants/routes';

class Navigation extends Component {
  render() {
    const { firebase, auth } = this.props;
    if (!isLoaded(auth) || auth.isEmpty) {
      return null;
    }

    return (
      <div className="bar-1 col-lg-11 col-md-12 text-right text-left-xs text-left-sm">
        <div className="bar__module">
          <ul className="menu-horizontal text-left">
            <li>
              <Link to={homePath}>Home</Link>
            </li>
            <li>
              <Link to={browsePath}>University</Link>
            </li>
            <li>
              <Link to={coachingPath}>Coaching</Link>
            </li>
            <li>
              <Link to={eventsPath}>Events</Link>
            </li>
            <li>
              <Link to={podcastsPath}>Podcast</Link>
            </li>
            <li>
              <Link to={jobsPath}>Job Network</Link>
            </li>
            <li>
              <Link to={acceleratorPath}>Accelerator</Link>
            </li>
            <li>
              <Link to={'#'}>Help</Link>
            </li>
          </ul>
        </div>
        <div className="bar__module">
          <LinkNormal className="btn btn--sm btn--primary type--uppercase" to={'#'} onClick={($e) => {
            $e.preventDefault();
            firebase && firebase.logout();
          }}>
            <span className="btn__text">
                Log Out
            </span>
          </LinkNormal>
        </div>
      </div>
    );
  }
}

const WithFirebase = withFirebase(Navigation);
export default enhance(WithFirebase);
