import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';


class TimelineItem extends Component {
  static propTypes = {
    title: PropTypes.string,
    imageSrc: PropTypes.string,
    short: PropTypes.string,
    type: PropTypes.string,
  }

  componentDidMount() {
    if (window.mr) {
      window.mr.status.windowLoadPending = true;
      window.mr.documentReady();
    }
    this.initPlaceholderImage();
  }

  initPlaceholderImage() {
    if (!(typeof window != 'undefined' && window.document)) {
      return;
    }

    const { imageSrc } = this.props;
    if (!imageSrc) {
      const image = ReactDOM.findDOMNode(this.refs.image);
      const Holder = require('holderjs');
      Holder.run({
        domain: 'holder.js',
        images: image,
      })
    }
  }

  render() {
    const { title, imageSrc, url, short } = this.props;
    let link;

    if (url) {
      link = <Link to={url}><img alt={title} src={imageSrc} data-src={!imageSrc && 'holder.js/600x280?theme=sky&text=No Image'} ref="image" /></Link>
    } else {
      link = <a href={imageSrc} data-lightbox="true"><img alt={title} src={imageSrc} data-src={!imageSrc && 'holder.js/313x176?theme=sky&text=No Image'} ref="image" /></a>
    }

    return (
      <div className="process__item">
        <h4>{title}</h4>
        {link}
        <p>{short}</p>
      </div>
    );
  }
}

export default TimelineItem;
