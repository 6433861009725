import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { Router, Route, Redirect, Switch } from 'react-router-dom';
import createBrowserHistory from 'history/createBrowserHistory';
import ReactGA from 'react-ga';
import * as Sentry from '@sentry/browser';

import store from 'store';
import { googleAnalytics, sentry } from 'config';

import NotFound from 'screens/NotFound';
import Home from 'screens/Home';
import Browse from 'screens/Browse';
import Episode, { Page } from 'screens/Episode';
import Login from 'screens/Login';
import Signup from 'screens/Signup';
import Payment from 'screens/Payment';
import PreSurvey from 'screens/PreSurvey';
import ScrollToTop from 'components/ScrollToTop';
import { Success } from 'screens/Episode';
import Coaching from 'screens/Coaching';
import Events from 'screens/Events';
import Podcasts from 'screens/Podcasts';
import Pricing from 'screens/Pricing';
import Jobs from 'screens/Jobs';
import Accelerator from 'screens/Accelerator';
import Overview from 'screens/Overview';
import Shari from 'screens/Shari';
import Community from 'screens/Community';
import Terms from 'screens/Terms';
import PostSurvey from 'screens/PostSurvey';
import ToddDuckett from 'screens/ToddDuckett';
import Studios from 'screens/Studios';
import {
  homePath,
  browsePath,
  episodePath,
  loginPath,
  signupPath,
  paymentPath,
  episodesPath,
  episodePagePath,
  episodeSuccessPath,
  preSurveyPath,
  coachingPath,
  eventsPath,
  podcastsPath,
  pricingPath,
  jobsPath,
  acceleratorPath,
  overviewPath,
  shariPath,
  communityPath,
  termsPath,
  postSurveyPath,
  toddDuckettPath,
  studiosPath
} from 'constants/routes';
// We need to use relative as 'firebase' conflicts with package
import { auth } from '../../firebase';

import './App.css';

class App extends Component {
  constructor() {
    super()
    if (process.env.NODE_ENV !== 'development') {
      Sentry.init({
        dsn: sentry.dsn,
        release: sentry.release
      });
    }

    this.history = createBrowserHistory();
    auth.onAuthStateChanged(user => {
      const { __insp } = window;
      if (user) {
        // Establish the user ID through user_id which session was initiated.
        __insp.push(['identify', user.email]);
        ReactGA.initialize(googleAnalytics.trackingId, { gaOptions: { userId: user.email } });
        if (process.env.NODE_ENV !== 'development') {
          Sentry.configureScope((scope) => {
            scope.setUser({"email": user.email});
          });
        }
      } else {
        __insp.push(['identify', null]);
        ReactGA.initialize(googleAnalytics.trackingId, { gaOptions: { userId: null } });
        if (process.env.NODE_ENV !== 'development') {
          Sentry.configureScope((scope) => {
            scope.setUser({"email": null});
          });
        }
      }
      this.trackGA(this.history.location);
    });
    this.history.listen(this.trackGA);
  }

  trackGA(location) {
    ReactGA.pageview(location.pathname);
  }

  render() {
    return (
      <Provider store={store}>
        <Router history={this.history}>
          <ScrollToTop>
            <Switch>
              <Route exact path={homePath} component={Home} />
              <Route exact path={browsePath} component={Browse} />
              <Route exact path={episodeSuccessPath} component={Success} />
              <Route exact path={episodePagePath} component={Page} />
              <Route exact path={episodePath} component={Episode} />
              <Route exact path={preSurveyPath} component={PreSurvey} />
              {/* /episodes without any id must redirect to browse page */}
              <Redirect from={episodesPath} to={browsePath} />
              <Route exact path={loginPath} component={Login} />
              <Route exact path={signupPath} component={Signup} />
              <Route exact path={paymentPath} component={Payment} />
              <Route exact path={coachingPath} component={Coaching} />
              <Route exact path={eventsPath} component={Events} />
              <Route exeact path={podcastsPath} component={Podcasts} />
              <Route exeact path={pricingPath} component={Pricing} />
              <Route exeact path={jobsPath} component={Jobs} />
              <Route exeact path={acceleratorPath} component={Accelerator} />
              <Route exeact path={overviewPath} component={Overview} />
              <Route exeact path={shariPath} component={Shari} />
              <Route exeact path={communityPath} component={Community} />
              <Route exeact path={termsPath} component={Terms} />
              <Route exeact path={postSurveyPath} component={PostSurvey} />
              <Route exeact path={toddDuckettPath} component={ToddDuckett} />
              <Route exeact path={studiosPath} component={Studios} />

              <Route component={NotFound} />
            </Switch>
          </ScrollToTop>
        </Router>
      </Provider >
    );
  }
}

export default App;
