import React, { Component } from 'react';
import { withFirebase, isLoaded } from 'react-redux-firebase';
import { Link } from 'react-router-dom';
import { enhance } from 'utils';
import LinkNormal from 'components/LinkNormal';
import PropTypes from 'prop-types';
import b from 'images/b.png';
// For some reason I could not use 'constants'
// import { copyright } from '../../constants';
import { homePath, browsePath, coachingPath, eventsPath, podcastsPath, jobsPath, acceleratorPath } from 'constants/routes';

class ModalNavigation extends Component {
  static propTypes = {
    back: PropTypes.bool,
  };

  render() {
    const { firebase, auth, back } = this.props;
    if (!isLoaded(auth) || auth.isEmpty) {
      return null;
    }

    let button;
    if (back) {
      button = (<Link className={`menu-toggle`} to={'/browse'}>
        <img alt="Back" src={require("images/back.png")} className="logo image--xs" style={{ paddingTop: '10px' }} />
      </Link>);
    } else {
      button = (<LinkNormal className={`menu-toggle modal-trigger`} to={'#'}>
        <i className="stack-interface stack-menu"></i>
      </LinkNormal>);
    }

    return (
      <div className="col-6 d-flex justify-content-end">
        <div className="bar__module">
          <div className="modal-instance pull-right">
            {button}
            <div className="modal-container menu-fullscreen">
              <div className="modal-content" data-width="100%" data-height="100%">
                <div className="pos-vertical-center pos-asbolute text-center">
                  <div className="heading-block">
                    <img alt="Logo" src={b} className="logo image--xs" />
                    <p className="lead">
                      Life hits fast. Have a plan.
                    </p>
                  </div>
                  <ul className="menu-vertical">
                    <li className="h4">
                      <Link to={homePath}>Home</Link>
                    </li>
                    <li className="h4">
                      <Link to={browsePath}>University</Link>
                    </li>
                    <li className="h4">
                      <Link to={coachingPath}>Coaching</Link>
                    </li>
                    <li className="h4">
                      <Link to={eventsPath}>Events</Link>
                    </li>
                    <li className="h4">
                      <Link to={podcastsPath}>Podcast</Link>
                    </li>
                    <li className="h4">
                      <Link to={jobsPath}>Job Network</Link>
                    </li>
                    <li className="h4">
                      <Link to={acceleratorPath}>Accelerator</Link>
                    </li>
                    <li className="h4">
                      <Link to={'#'}>Help</Link>
                    </li>
                    <li className="h4">
                      <LinkNormal to={'#'} onClick={($e) => {
                        $e.preventDefault();
                        firebase && firebase.logout();
                      }}>Logout</LinkNormal>
                    </li>
                  </ul>
                </div>
                {/* <div className="pos-absolute pos-bottom text-center">
                  <ul className="social-list list-inline list--hover">
                    <li>
                      <Link to={'#'}>
                        <i className="socicon socicon-twitter icon icon--xs"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to={'#'}>
                        <i className="socicon socicon-facebook icon icon--xs"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to={'#'}>
                        <i className="socicon socicon-instagram icon icon--xs"></i>
                      </Link>
                    </li>
                  </ul>
                  <p className="type--fine-print type--fade" dangerouslySetInnerHTML={{ __html: copyright }}></p>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const WithFirebase = withFirebase(ModalNavigation);
export default enhance(WithFirebase);
