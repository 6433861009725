import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { MasonryItem } from '.';

class Masonry extends Component {
  static propTypes = {
    extraClass: PropTypes.string
  }

  render() {
    const extraClass = this.props.extraClass || '';
    const children = (_.isArray(this.props.children)
      ? this.props.children
      : this.props.children ? [this.props.children] : [])
      .filter(child => child && child.type === MasonryItem)
    return (<div className={'masonry ' + extraClass} style={{ margin: 'auto' }}>
      <div className="masonry__container">
        {children}
      </div>
    </div>)
  }
}

export default Masonry;
