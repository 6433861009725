import React, { Component } from 'react';
import _ from 'lodash';
import { compose } from 'redux';
import { firestoreConnect, isLoaded } from 'react-redux-firebase';
import { connect } from 'react-redux';

import Layout from 'components/Layout';
import WithAuthorization from 'components/WithAuthorization';
import { authCondition } from 'utils';
import { Content } from 'components/Layout';
import Button from 'components/Button';
import LinkNormal from 'components/LinkNormal';
import { Header } from 'components/Layout';
import Loading from 'components/Loading';
import * as collections from 'constants/collections';

class Success extends Component {
  getLastPage() {
    const { episode } = this.props;

    return episode.content[episode.content.length - 1];
  }

  updateProfile = () => {
    this.props.firebase.updateProfile({ betaCompleted: true })
  }

  render() {
    const { achievement, episode } = this.props;
    if (!isLoaded(achievement, episode)) {
      return (<Loading />);
    }

    const lastEpisode = this.getLastPage();

    return (
      <Layout>
        <Header back={true}/>
        <Content mainClass={'bg--dark'}>
          <section className="text-center" style={{paddingBbottom: '3em'}}>
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <h1>{achievement.name}</h1>
                </div>
              </div>
            </div>
          </section>
          <section className="text-center" style={{marginBottom: '0px'}}>
            <div className="container">
              <div className="row justify-content-center">

                <div className="col-md-10 col-lg-8">
                  <div style={{marginBottom: '20px', position: 'relative'}}>
                    <iframe
                      title={achievement.name}
                      src={achievement.videoBadge}
                      width="700" height="500" frameBorder="0" webkitallowfullscreen="true" mozallowfullscreen="true"
                      allowFullScreen></iframe>
                    <p className="lead" style={{paddingTop: '20px'}}>
                      { `${achievement.description} And you've unlocked an achievement.
                      Achievements help track your progress and are unlocked when you do things like: watching an episode,
                      participating in Genius activities, or utilizing the services provided to you by BlitzPrep.`}
                    </p>
                    <div className="col-md-3 mb--1">

                      <div className="notification pos-right pos-bottom col-6" data-animation="from-right"
                           data-notification-link="cookies-1" data-autoshow="1000"
                           style={{marginBottom: '20px', marginRight: '20px'}}>
                        <div className="boxed boxed--border bg--primary border--round" style={{borderColor: 'white'}}>
                          <span>Congrats! You just completed your first episode! <span role="img" aria-label="Tada">🎉</span> &nbsp; And you unlocked a new achievement.
                              <br/><LinkNormal to={'#'} className="notification-close">Close Notification</LinkNormal>
                          </span>
                        </div>
                      </div>
                    </div>
                    {/*
                    <div>
                      <LinkNormal className="btn btn--icon bg--facebook" to={'#'}>
                        <span className="btn__text">
                            <i className="socicon socicon-facebook"></i>
                            Share Your Achievement on Facebook
                        </span>
                      </LinkNormal>
                      <LinkNormal className="btn btn--icon bg--twitter" to={'#'}>
                        <span className="btn__text">
                            <i className="socicon socicon-twitter"></i>
                            ReTweet on Twitter
                        </span>
                      </LinkNormal>
                    </div>
                    */}
                  </div>
                </div>
              </div>
            </div>
            <section>
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-md-10 col-lg-10">
                    <Button text={'Back'} path={`page-${lastEpisode.id}`}
                            extraClass={'btn--stroked'} align={'left'} tooltip={lastEpisode.heading}/>
                    <span style={{float: 'right'}}>
                      <button className={'btn btn--primary type--uppercase'} onClick={this.updateProfile} style={{ fontSize: 'inherit',
                        paddingRight: '2.78571429em',
                        paddingLeft: '2.78571429em',
                        height: '42px' }}>
                        <span className="btn__text">Next Episode</span>
                      </button>
                    </span>

                  </div>
                </div>
              </div>
            </section>
          </section>
        </Content>
      </Layout>
    );
  }
}

const FirestoreConnected = compose(
  firestoreConnect(({ match }) => [
    { collection: collections.ACHIEVEMENT, doc: match.params.episodeId },
    { collection: collections.EPISODE, doc: match.params.episodeId },
  ]),
  connect(({ firestore: { ordered } }) => ({
    achievement: _.first(ordered.achievements || []),
    episode: _.first(ordered.episodes || []),
  }))
)(Success);
export default WithAuthorization(authCondition)(FirestoreConnected);
