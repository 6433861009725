import Episode from './Episode';
import Page from './Page';
import Success from './Success';

export {
  Page,
  Success
}

export default Episode;
