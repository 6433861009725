import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';

class LinkInner extends Component {
  static propTypes = {
    title: PropTypes.string,
    icon: PropTypes.string,
    iconAlt: PropTypes.string,
    to: PropTypes.string,
    path: PropTypes.string,
    className: PropTypes.string,
    extraClass: PropTypes.string,
    style: PropTypes.object
  };

  render() {
    const { title, icon, iconAlt, to, path, className, extraClass, children } = this.props;
    const props = _.omit(this.props, ['title', 'icon', 'iconAlt', 'to', 'path', 'className', 'extraClass', 'children']);

    if (path) {
      const content = children || <span className="btn__text" style={icon && { maxWidth: '50px' }}>
        {icon && <img alt={iconAlt} src={icon} style={{ width: '20px', marginRight: '2px', marginLeft: '-5px' }} />}
        {title}
      </span>;

      return (
        <a className={`${className || 'inner-link btn type--uppercase'} ${extraClass||''}`} ref={el => this.el = el} href={path} {...props}>
          {content}
        </a>
      );
    }

    return (
      <Link className={`${className || 'inner-link btn type--uppercase'} ${extraClass || ''}`} ref={el => this.el = el} to={to||''} {...props}>
        {icon
        ? <span className="btn__text" style={{ maxWidth: '50px' }}>
          <img alt={iconAlt} src={icon} style={{ width: '20px', marginRight: '2px', marginLeft: '-5px' }} />
          {title}
        </span>
        : <span className="btn__text">
          {title}
        </span>}
      </Link>
    );
  }
}

export default LinkInner;
