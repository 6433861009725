import React, {Component} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import LinkInner from 'components/LinkInner/LinkInner';

class Button extends Component {
  static propTypes = {
    text: PropTypes.string,
    path: PropTypes.string,
    extraClass: PropTypes.string,
    align: PropTypes.string,
    tooltip: PropTypes.string,
  }

  render() {
    const { text, path, extraClass, align, tooltip, disabled } = this.props;
    const props = _.omit(this.props, ['text', 'path', 'extraClass', 'align', 'tooltip', 'disabled', 'children']);
    return (
      <span style={{float: align}} data-tooltip={tooltip}>
        <LinkInner title={text} to={path} extraClass={` btn--primary type--uppercase ${extraClass}`}
          disabled={disabled} {...props} />
      </span>
    );
  }
}

export default Button;
