import React, { Component } from 'react';
import PropTypes from 'prop-types';

import BackgroundImage from 'components/BackgroundImage';

class MasonryItem extends Component {
  static propTypes = {
    extraClass: PropTypes.string,
    title: PropTypes.string.isRequired,
    short: PropTypes.string.isRequired,
    imageSrc: PropTypes.string.isRequired,
  }

  render() {
    const { title, short, imageSrc, extraClass } = this.props;
    return (<div className={`masonry__item ${extraClass}`} data-masonry-filter="Digital">
      <div className="project-thumb hover-element height-50">
        <a href={imageSrc} data-lightbox="true">
          <div className="hover-element__initial">
            <BackgroundImage source={imageSrc} />
          </div>
          <div className="hover-element__reveal" data-overlay="9">
            <div className="project-thumb__title">
              <h5>{title}</h5>
              <span>{short}</span>
            </div>
          </div>
        </a>
      </div>
    </div>);
  }
}

export default MasonryItem;
