import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { homePath } from 'constants/routes';

// Images
import b from 'images/b.png';
import bDark from 'images/b-dark.png';
import { ModalNavigation } from '.';

class Header extends Component {
  static propTypes = {
    back: PropTypes.bool,
    isLogoLink: PropTypes.bool,
  }

  renderLogo() {
    const { isLogoLink } = this.props;
    const logo = (<Fragment>
      <img className="logo logo-dark" alt="logo" src={bDark} />
      <img className="logo logo-light" alt="logo" src={b} />
    </Fragment>)

    if (isLogoLink === false) {
      return logo;
    }

    return (<Link to={homePath}>{logo}</Link>)
  }

  render() {
    const { back, children } = this.props;

    return (
      <div className="nav-container">
        <nav className="bar bar-toggle bar--transparent bar--absolute bg--dark">
          <div className="container">
            {/* TODO: Remove this TODO if not needed, this class was here -align-items-center- */}
            <div className="row">
              <div className={children ? 'col-lg-1 col-md-2 hidden-xs' : 'col-6'}>
                <div className="bar__module">
                  {this.renderLogo()}
                </div>
              </div>
              {children || <ModalNavigation back={!!back} />}
            </div>
          </div>
        </nav>
      </div>
    );
  }
}

export default Header;
