import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import BackgroundImage from 'components/BackgroundImage';

class SliderItem extends Component {
  static propTypes = {
    heading: PropTypes.string.isRequired,
    subheading: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
    to: PropTypes.string,
    grayOut: PropTypes.bool,
    className: PropTypes.string,
    clickable: PropTypes.bool
  };

  render() {
    const { heading, subheading, description, image, to, grayOut, clickable, className } = this.props;
    if (clickable === false) {
      return (<li className={className || 'col-md-3 col-12'} data-overlay="5">
        <BackgroundImage source={image} />
        <div className="pos-vertical-center">
          <div className="row">
            <div className="col-md-12">
              <h2>{heading}</h2>
              <h4 dangerouslySetInnerHTML={{ __html: description }} />
            </div>
          </div>
        </div>
      </li>);
    }

    return (<li className={className || 'col-md-3 col-12'}>
      <div className="project-thumb hover-element border--round hover--active">
        <Link to={to || ''}>
          <div className="hover-element__initial">
            <BackgroundImage source={image} />
          </div>
          {grayOut
            ? <div className="hover-element__reveal" data-overlay="9">
              <div className="project-thumb__title">
                <h4>{heading}</h4>
                <span>{subheading}</span>
                {description && <h5 dangerouslySetInnerHTML={{ __html: description }} />}
              </div>
            </div>
            : <div className="hover-element__reveal" data-scrim-top="5">
              <div className="project-thumb__title">
                <h4>{heading}</h4>
                <span>{subheading}</span>
                {description && <h5 dangerouslySetInnerHTML={{ __html: description }} />}
              </div>
            </div>}
        </Link>
      </div>
    </li>);
  }
}

export default SliderItem;
