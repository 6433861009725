import React, { Component } from 'react';

import WithAuthorization from 'components/WithAuthorization';
import Video from 'components/Video';
import { authCondition } from 'utils';

import Layout, { Navigation, Header } from 'components/Layout';

class ToddDuckett extends Component {
  render() {
    return (
      <Layout>
        <Header><Navigation/></Header>
        <section className="imagebg text-center" data-overlay="1" style={{backgroundColor: 'black'}}>
          <div className="container" style={{paddingTop: '30px'}}>
            <div className="row">
              <div className="col-md-12 ">
                <h1>Duckett Beta Outro</h1>
                <div className="col-md-12 col-lg-12">
                  <div style={{ height: '720px', position: 'relative' }}>
                    <Video source={'https://vimeo.com/304491383/78ba3c0778'} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

export default WithAuthorization(authCondition)(ToddDuckett);
