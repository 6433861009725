import React, { Component } from 'react';
import Lottie from 'react-lottie';
import { connect } from 'react-redux';

import { loading } from 'constants/animations';

import { actions as sessionActions } from 'reducers/session';

import bDark from 'images/b-dark.png';

class Loading extends Component {
  componentWillMount() {
    const { session } = this.props
    if (!session.quote) {
      this.props.loadQuote();
    }
  }

  render() {
    const { session } = this.props;
    const quote = !session.isReady && session.quote;
    return (<section className="height-100 imagebg text-center">
      <div className="container pos-vertical-center">
        <div className="row">
          <div className="col-md-12">
            <img className="logo logo-light" alt="BlitzPrep University" src={bDark} width={200} style={{ marginBottom: 0 }} />
            <Lottie options={loading} height={60} width={200} />
            {quote &&
            <div className="container-fluid" style={{ marginTop: '10px' }}>
              <div className="row justify-content-md-center">
                <p className="quote col-md-6 col-md-offset-3 col-lg-offset-3">
                  <cite>"{quote.quote}"</cite>
                  {quote.author ? `by ${quote.author}` : ''}
                </p>
              </div>
            </div>}
          </div>
        </div>
      </div>
    </section>);
  }
}

const mapStateToProps = ({ session }) => ({
  session
});
const mapDispatchToProps = ({
  ...sessionActions,
});
export default connect(mapStateToProps, mapDispatchToProps)(Loading);
