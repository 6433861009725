import { createStore, compose, applyMiddleware } from 'redux';
// We can integrate with firebase but I dont think is necessary
// http://react-redux-firebase.com/docs/integrations/thunks.html
import { reactReduxFirebase } from 'react-redux-firebase';
import { reduxFirestore } from 'redux-firestore'
import thunk from 'redux-thunk';

import firebase from './firebase';
import rootReducer from './reducers';

// react-redux-firebase config
const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true // Firestore for Profile instead of Realtime DB
}

// Add reactReduxFirebase enhancer when making store creator
const createStoreWithFirebase = compose(
  reactReduxFirebase(firebase, rrfConfig), // firebase instance as first argument
  reduxFirestore(firebase) // <- needed if using firestore
)(createStore);

// Create store with reducers and initial state
const initialState = {}

const store = createStoreWithFirebase(rootReducer, initialState, applyMiddleware(thunk));

export default store;
