import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withFirebase } from 'react-redux-firebase';
import { Link } from "react-router-dom";
import _ from 'lodash';

import WithAuthorization from 'components/WithAuthorization';
import { unAuthCondition } from 'utils';
import { loginPath, termsPath, homePath } from 'constants/routes';
import { actions as loginActions } from 'reducers/login';
import { actions as signupActions } from 'reducers/signup';
import Layout from 'components/Layout';
import BackgroundImage from 'components/BackgroundImage';

import TJ from 'images/TJ.jpg'

import './Signup.css';

class Signup extends Component {
  static propTypes = {
    email: PropTypes.string,
    password: PropTypes.string,
    rePassword: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    onEmailChanged: PropTypes.func,
    onPasswordChanged: PropTypes.func
  };

  onSubmit = (event) => {
    const {
      email,
      password,
      rePassword,
      firstName,
      lastName,
      firebase,
      onError,
      clear,
      clearLogin,
    } = this.props;

    if (_.isEmpty(firstName) && _.isEmpty(lastName)) {
      onError({
        message: 'First name and Las name are required',
      });
    } else if (_.isEmpty(email) || _.isEmpty(password)) {
      onError({
        message: 'Email and Password are required',
      });
    } else {
      // email and password are not empty
      if (password === rePassword) {
        firebase.createUser({ email, password }, { displayName: `${firstName} ${lastName}`, firstName, lastName, email })
          .then(result => {
            console.log(result);
          }, err => {
            onError({
              message: 'There was a problem creating the user.',
            });
          });
        clearLogin();
        clear();
      } else {
        clearLogin();
        clear();
        onError({
          message: 'Password and confirm password need to be the same',
        });
      }
    }

    event.preventDefault();
  }

  render() {
    // TODO: `error` needs to be displayed
    const {
      email,
      password,
      rePassword,
      firstName,
      lastName,
      onFirstNameChanged,
      onLastNameChanged,
      onEmailChanged,
      onPasswordChanged,
      onRePasswordChanged,
      error,
    } = this.props;

    return (
      <Layout>
        <section className="imageblock switchable feature-large height-100 ">
          <div className="imageblock__content col-lg-6 col-md-4 pos-right" data-overlay="1">
            <BackgroundImage source={TJ} />
          </div>
          <div className="container pos-vertical-center">
            <div className="row ">
              <div className="col-lg-5 col-md-7">
                <h1>Sign Up For The BlitzPrep Beta.</h1>
                <p className="lead">
                </p>
                <div className="bg--secondary boxed boxed--border">
                  <form onSubmit={this.onSubmit}>
                    <input
                      value={firstName}
                      onChange={event => onFirstNameChanged(event.target.value) }
                      type="text"
                      placeholder="First name"
                    />
                    <input
                      value={lastName}
                      onChange={event => onLastNameChanged(event.target.value) }
                      type="text"
                      placeholder="Last name"
                    />
                    <input
                      value={email}
                      onChange={event => onEmailChanged(event.target.value) }
                      type="text"
                      placeholder="Email Address"
                    />
                    <input
                      value={password}
                      onChange={event => onPasswordChanged(event.target.value) }
                      type="password"
                      placeholder="Password"
                    />
                    <input
                      value={rePassword}
                      onChange={event => onRePasswordChanged(event.target.value) }
                      type="password"
                      placeholder="Confirm Password"
                    />
                    <span style={{color: 'red'}}>{error && error.message}</span>
                    <span className="type--fine-print block" style={{ marginTop: '12px' }}>By signing up for BlitzPrep you agree to our <Link to={termsPath}>Terms and Conditions</Link></span>
                    <button type="submit" className="btn btn--light type--uppercase signupButton">Sign up</button>
                  </form>
                </div>
                <span className="type--fine-print block">You already have an account? <Link to={loginPath}>Log in</Link></span>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

const mapStateToProps = state => ({
  email: state.login.email,
  ...state.signup,
});

const mapDispatchToProps = ({
  onEmailChanged: loginActions.onEmailChanged,
  clearLogin: loginActions.clear,
  ...signupActions,
});

const WithFirebase = withFirebase(connect(mapStateToProps, mapDispatchToProps)(Signup));
export default WithAuthorization(unAuthCondition, homePath)(WithFirebase);
