import _ from 'lodash';

import quotes from 'data/quotes';

// const string for actions in uppercase
const SESSION_READY = 'SESSION_READY';
const SESSION_QUOTE_LOAD = 'SESSION_QUOTE_LOAD';
const SESSION_EPISODE_PAGE_CHANGED = 'SESSION_EPISODE_PAGE_CHANGED';

// actions
export const actions = {
  loadQuote: () => (dispatch) => {
    dispatch({ type: SESSION_QUOTE_LOAD });
    setTimeout(() => {
      dispatch({ type: SESSION_READY });
    }, 5000);
  },
  changeEpisodePage: (episodeId, pageId) => ({ type: SESSION_EPISODE_PAGE_CHANGED, episodeId, pageId }),
};

// initial state
const initialState = {
  episodeNav: { },
  isReady: false,
  quote: null,
};

// action handlers
const actionHandlers = {
  [SESSION_READY]: (state) => ({ ...state, isReady: true }),
  [SESSION_QUOTE_LOAD]: (state) => ({ ...state, quote: _.sample(quotes) }),
  [SESSION_EPISODE_PAGE_CHANGED]: (state, { episodeId, pageId }) => ({ ...state, episodeNav: { ...state.episodeNav, [episodeId]: parseInt(pageId) } })
};

export const reducer = (state = initialState, action) => {
  const handler = actionHandlers[action.type]

  return handler ? handler(state, action) : state
};
