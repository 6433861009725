import React from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'react-redux-firebase';
import querystring from 'querystring';
import _ from 'lodash';

import { site } from 'config';

export const enhance = connect(
  // Map redux state to component props
  ({ firebase: { auth, profile }, session }) => ({
    auth,
    profile,
    session
  })
);

// Let's fill hidden fields to identify user
export const identifyMeOnTypeform = (props, url) => {
  if (_.isEmpty(url)) {
    return undefined;
  }
  const { email } = props.auth;
  const { displayName } = props.profile;
  const hiddenFields = { name: displayName, email, source: site.domain };
  return `${url}?${querystring.stringify(hiddenFields)}`;
}

export const authCondition = ({ auth }) => !isEmpty(auth);

export const unAuthCondition = ({ auth }) => isEmpty(auth);

export const preSurveyCondition = ({ auth, profile }) => !!profile.presurveyCompleted;

export const notPreSurveyCondition = ({ auth, profile }) => !profile.presurveyCompleted;

// export const episodePageAccessCondition = (props) => {
//   if (!authCondition(props)) {
//     return false;
//   }

//   const { profile, match, history, session } = (nextProps || this.props);
// }

export const renderField = ({ input, label, type, meta: { touched, error } }) => (
  <div>
    <label>{label}</label>
    <div>
      <input {...input} placeholder={label} type={type} />
      {touched && error && <span>{error}</span>}
    </div>
  </div>
);
