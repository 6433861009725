import { combineReducers } from 'redux';
import firebase from './firebase';
import firestore from './firestore';
import { reducer as login } from './login';
import { reducer as signup } from './signup';
import { reducer as reduxFormReducer } from 'redux-form';
import { reducer as payment } from './payment';
import { reducer as session } from './session';

const rootReducer = combineReducers({
  firebase,
  firestore,
  login,
  signup,
  form: reduxFormReducer,
  payment,
  session
});

export default rootReducer;
