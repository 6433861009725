import React from 'react';
import ReactDOM from 'react-dom';

// Project styles
import 'bootstrap/dist/css/bootstrap.min.css';
import 'styles/stack-interface.css';
import 'styles/socicon.css';
import 'styles/lightbox.min.css';
import 'styles/flickity.css';
import 'styles/iconsmind.css';
import 'styles/jquery.steps.css';
import 'styles/theme.css';
import 'styles/font-rubiklato.css';
// This is what custom.css was
import 'index.css';

import App from './components/App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
