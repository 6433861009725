import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

class LinkNormal extends Component {
  static propTypes = {
    to: PropTypes.string.isRequired,
  };

  render() {
    const { to, children } = this.props;
    const props = _.omit(this.props, ['to', 'href']);
      
    return (<a href={to} {...props}>{children}</a>);
  }
}

export default LinkNormal;
