import React, { Fragment } from 'react';

import WithAuthorization from 'components/WithAuthorization';
import Video from 'components/Video';
import { authCondition, identifyMeOnTypeform } from 'utils';

import LinkInner from 'components/LinkInner';
import { Teaser } from 'components/Screen';
import Masonry, { MasonryItem } from 'components/Masonry';
import Anchor from 'components/Anchor';
import TypeformEmbed from 'components/TypeformEmbed';

// Images
import podcasts from 'images/podcasts1.jpg';
import podcasts2 from 'images/podcasts2.jpg';
import podcasts3 from 'images/podcasts3.jpg';
import podcasts4 from 'images/podcasts4.jpg';
import podcasts5 from 'images/podcasts5.jpg';

class Podcasts extends Teaser {
  renderContent() {
    return (<Fragment>
      <section className="imagebg text-center" data-overlay="1" style={{backgroundColor: 'black'}}>
        <div className="container" style={{paddingTop: '30px'}}>
          <div className="row">
            <div className="col-md-12">
              <h1>BlitzPrep Podcasts</h1>
              <div className="col-md-12 col-lg-12">
                <h4 style={{paddingBottom:'10px'}}>BlitzPrep Podcasts offer athletes a voice when they step off the field.
                Hear powerfully engaging and intimate interviews, stories, and conversations around what it means to be an athlete in today’s society.
                </h4>
                <LinkInner title={'Get Started'} path={'#form'} style={{marginBottom: '30px'}}/>
                <img alt="On-air" src={podcasts} style={{width:'100%', paddingBottom:'100px'}}/>
                <div style={{ height: '500px', position: 'relative' }}>
                  <Video source={'https://vimeo.com/304434570/3bc941d1a6'} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="unpad bg--dark" style={{ paddingBottom: '75px', paddingTop: '75px' }}>
        <Masonry extraClass="col-11">
          <MasonryItem
            title='The Foundation Studio'
            short='Detroit, MI'
            extraClass='col-lg-4 col-md-6 col-12'
            imageSrc={podcasts2} />

          <MasonryItem
            title='Mike Martin'
            short='Former NFL Defensive End'
            extraClass='col-lg-8 col-md-6 col-12'
            imageSrc={podcasts3} />

          <MasonryItem
            title='Rude Jude'
            short='Host of "The All Out Show"'
            extraClass='col-lg-8 col-md-6 col-12'
            imageSrc={podcasts4} />

          <MasonryItem
            title='Podcast For Shade 45'
            short='Detroit, MI'
            extraClass='col-lg-4 col-md-6 col-12'
            imageSrc={podcasts5} />
        </Masonry>
      </section>
        <section className="text-center">
          <div className="container">
            <Anchor tag="form" />
            <h1>This feature has not launched yet! Please tell us what you think below!</h1>
            <TypeformEmbed url={identifyMeOnTypeform(this.props, 'https://blitzprep.typeform.com/to/ByPt9V')} style={{ width: '100%', height: '500px', paddingTop: '0' }} />
          </div>
        </section>
    </Fragment>);
  }
}

export default WithAuthorization(authCondition)(Podcasts);
