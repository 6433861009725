import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { SliderItem } from '.';

class Slider extends Component {
  static propTypes = {
    paging: PropTypes.bool,
    dataTiming: PropTypes.number,
    extraClass: PropTypes.string
  };

  createFlickity() {
    const { $ } = window;
    const slider = this.$el;
    const sliderInitializer = slider.find('ul.slides');
    sliderInitializer.find('li').addClass('slide');
    const childnum = sliderInitializer.find('li').length;

    const themeDefaults = {
      cellSelector: '.slide',
      cellAlign: 'left',
      wrapAround: true,
      pageDots: false,
      prevNextButtons: false,
      autoPlay: true,
      draggable: (childnum < 2 ? false : true),
      imagesLoaded: true,
      accessibility: true,
      rightToLeft: false,
      initialIndex: 0,
      freeScroll: false
    };

    // Attribute Overrides - options that are overridden by data attributes on the slider element
    const ao = {};
    ao.pageDots = (slider.attr('data-paging') === 'true' && sliderInitializer.find('li').length > 1) ? true : undefined;
    ao.prevNextButtons = slider.attr('data-arrows') === 'true' ? true : undefined;
    ao.draggable = slider.attr('data-draggable') === 'false' ? false : undefined;
    ao.autoPlay = slider.attr('data-autoplay') === 'false' ? false : (slider.attr('data-timing') ? parseInt(slider.attr('data-timing'), 10) : undefined);
    ao.accessibility = slider.attr('data-accessibility') === 'false' ? false : undefined;
    ao.rightToLeft = slider.attr('data-rtl') === 'true' ? true : undefined;
    ao.initialIndex = slider.attr('data-initial') ? parseInt(slider.attr('data-initial'), 10) : undefined;
    ao.freeScroll = slider.attr('data-freescroll') === "true" ? true : undefined;

    // Set data attribute to inidicate the number of children in the slider
    slider.attr('data-children', childnum);

    slider.data('sliderOptions', $.extend({}, themeDefaults, ao));

    $(sliderInitializer).flickity(slider.data('sliderOptions'));

    $(sliderInitializer).on('scroll.flickity', function (event, progress) {
      if (slider.find('.is-selected').hasClass('controls--dark')) {
        slider.addClass('controls--dark');
      } else {
        slider.removeClass('controls--dark');
      }
    });
  }

  componentDidMount() {
    const { $ } = window;
    this.$el = $(this.el);
    this.createFlickity();
  }

  componentWillUpdate() {
    const slider = this.$el;
    const sliderInitializer = slider.find('ul.slides');
    sliderInitializer.data('flickity') && sliderInitializer.flickity('destroy');
  }

  componentDidUpdate() {
    this.createFlickity();
  }

  componentWillUnmount() {
    const { $ } = window;
    const slider = this.$el;
    const sliderInitializer = slider.find('ul.slides');
    $(sliderInitializer).off('scroll.flickity');
    sliderInitializer.data('flickity') && sliderInitializer.flickity('destroy');
  }

  render() {
    const children = (this.props.children || []).filter(child => child.type === SliderItem);
    const { paging, dataTiming, style, extraClass } = this.props;

    return (
      <div className={`slider ${extraClass}`} ref={el => this.el = el} data-paging={paging} data-timing={dataTiming} style={(style || { zIndex: 300 })}>
        <ul className="slides">
          {children}
        </ul>
      </div>
    )
  }
}

export default Slider;
