import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as typeformEmbed from '@typeform/embed';

const styleDefault = {
};

// Implementation idea taken from here
// https://github.com/alexgarces/react-typeform-embed/blob/master/src/components/ReactTypeformEmbed/index.js
class TypeformEmbed extends Component {
  static propTypes = {
    url: PropTypes.string.isRequired,
    popup: PropTypes.bool,
    hideHeaders: PropTypes.bool,
    hideFooter: PropTypes.bool,

    // Widget options
    opacity: PropTypes.number,
    buttonText: PropTypes.string,

    // Popup options
    mode: PropTypes.string,
    autoOpen: PropTypes.bool,
    autoClose: PropTypes.number,
    onSubmit: PropTypes.func,
  };

  componentDidMount() {
    const { url, hideHeaders, hideFooter, opacity, buttonText, popup, onSubmit = () => {} } = this.props;
    const options = {
      hideHeaders,
      hideFooter,
      opacity,
      buttonText,
      onSubmit,
    }

    // Popup Mode
    if (popup) {
      const { mode, autoOpen, autoClose } = this.props;
      // Load Typeform embed popup
      this.typeform = typeformEmbed.makePopup(url, Object.assign(options, {
        mode,
        autoOpen,
        autoClose,
      }));

      // Widget Mode (default)
    } else {
      const elm = this.typeformElm;
      // Load Typeform embed widget
      typeformEmbed.makeWidget(elm, url, options);
    }
  }

  render() {
    const { style: specificStyle } = this.props;
    const style = Object.assign({}, styleDefault, specificStyle);
    return (<div className="ReactTypeformEmbed" ref={tf => this.typeformElm = tf} style={style} />);
  }
}

export default TypeformEmbed;
