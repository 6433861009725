import React, { Fragment } from 'react';

import WithAuthorization from 'components/WithAuthorization';
import { authCondition, identifyMeOnTypeform } from 'utils';

import { Teaser } from 'components/Screen';
import LinkNormal from 'components/LinkNormal';
import LinkInner from 'components/LinkInner';
import Anchor from 'components/Anchor';
import Masonry, { MasonryItem } from 'components/Masonry';
import TypeformEmbed from 'components/TypeformEmbed';

// Images
import jobs1 from 'images/jobs1.jpg';
import jobs2 from 'images/jobs2.jpg';
import jobs3 from 'images/jobs3.jpg';
import jobs4 from 'images/jobs4.jpg';
import jobs5 from 'images/jobs5.jpg';
import jobs6 from 'images/jobs6.jpg';
import jobs7 from 'images/jobs7.jpg';

class Jobs extends Teaser {
  renderContent() {
    return (<Fragment>
      <section className="imagebg text-center" data-overlay="1" style={{backgroundColor: 'black'}}>
        <div className="container" style={{paddingTop: '30px'}}>
          <div className="row">
            <div className="col-md-12">
              <h1>BlitzPrep Career Services</h1>
              <div className="col-md-12 col-lg-12">
                <h4 style={{paddingBottom:'10px'}}>We connect your strengths and passions to thousands of employers who are looking for elite level talent.</h4>
                <LinkInner to={'#'} title={'Get Started'} path={'#form'} style={{marginBottom:'30px'}} />
                <img alt="Jack On Stage" src={jobs2} style={{width:'100%', paddingBottom:'40px'}}/>
                <h2>BlitzPrep University Graduates Earn More</h2>
                <h4 style={{paddingBottom:'20px'}}>All BlitzPrep members who graduate from BlitzPrep University will receive exclusive access
                to premier career opportunities, networking events, and much more.</h4>
                <LinkNormal to={'#'} className="btn btn--lg" style={{marginBottom:'30px'}}>
                  <span className="btn__text">Join Now</span>
                </LinkNormal>
                <img alt="Big House" src={jobs1} style={{width:'100%', paddingBottom:'40px'}}/>
                <h2>What's Included?</h2>
                <h4 style={{paddingBottom:'0px'}}>As a BlitzPrep Career Services member will have access to everything you need
                kick start your newest journey.
                </h4>
                <div className="row" style={{paddingBottom:'30px'}}>
                  <div className="col-md-12">
                    <ul style={{textAlign:'center'}}>
                      <li><h4>•	Resume Review</h4></li>
                      <li><h4>•	LinkedIn Review</h4></li>
                      <li><h4>•	Cover Letter Review</h4></li>
                      <li><h4>•	Myers-Briggs (MBTI) and The Strong Career Planner</h4></li>
                      <li><h4>•	Access to thousands of jobs</h4></li>
                      <li><h4>•	Career Coaching</h4></li>
                      <li><h4>•	Exclusive career-related videos &amp; lessons</h4></li>
                      <li><h4>•	Webinars with CEO's and Business Leaders</h4></li>
                    </ul>
                    <LinkNormal to={'#'} className="btn btn--lg" style={{marginBottom:'30px'}}>
                      <span className="btn__text">Join Now</span>
                    </LinkNormal>
                  </div>
                </div>
                <img alt="Resumes, LinkedIn, Cover letter" src={jobs3} style={{width:'100%'}}/>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="unpad bg--dark" style={{ paddingBottom: '75px', paddingTop: '75px' }}>
        <Masonry extraClass="col-11">
          <MasonryItem
            title='The Foundation Studio'
            short='Detroit, MI'
            extraClass='col-lg-4 col-md-6 col-12'
            imageSrc={jobs4} />

          <MasonryItem
            title='Qunicy Adams'
            short='Former NFL Defensive End'
            extraClass='col-lg-8 col-md-6 col-12'
            imageSrc={jobs5} />

          <MasonryItem
            title='Denard Robinson'
            short='Former NFL Running Back'
            extraClass='col-lg-8 col-md-6 col-12'
            imageSrc={jobs6} />

          <MasonryItem
            title='Mike Martin'
            short='Former NFL Defensive End'
            extraClass='col-lg-4 col-md-6 col-12'
            imageSrc={jobs7} />
        </Masonry>
      </section>
      <section className="text-center">
        <div className="container">
          <Anchor tag="form" />
          <h1>This feature has not launched yet! Please tell us what you think below!</h1>
          <TypeformEmbed url={identifyMeOnTypeform(this.props, 'https://blitzprep.typeform.com/to/gw5bQK')} style={{ width: '100%', height: '500px', paddingTop: '0' }} />
        </div>
      </section>
    </Fragment>);
  }
}

export default WithAuthorization(authCondition)(Jobs);
