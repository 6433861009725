import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Cards from 'react-credit-cards';
import {
  StripeProvider,
  Elements,
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  PostalCodeElement
} from 'react-stripe-elements';

import Layout, { Navigation, Header } from 'components/Layout';
import LinkInner from 'components/LinkInner';
import BackgroundImage from 'components/BackgroundImage';
import WithAuthorization from 'components/WithAuthorization';
import { postSurveyPath } from 'constants/routes';
import { authCondition } from 'utils';
import { stripe } from 'config';

// Actions
import { actions as paymentActions } from 'reducers/payment';

import brian1 from 'images/brian.jpg';

import 'react-credit-cards/es/styles-compiled.css';

class Payment extends Component {
  constructor() {
    super();

    this.state = { stripe: null };
  }

  componentDidMount() {
    // You can inject a script tag manually like this,
    // or you can use the 'async' attribute on the Stripe.js v3 <script> tag.
    const stripeJs = document.createElement('script');
    stripeJs.src = 'https://js.stripe.com/v3/';
    stripeJs.async = true;
    stripeJs.onload = () => {
      //   // The setTimeout lets us pretend that Stripe.js took a long time to load
      //   // Take it out of your production code!
      //   setTimeout(() => {
      this.setState({
        stripe: window.Stripe(stripe.apiKey),
      });
      // }, 500);
    };
    document.body && document.body.appendChild(stripeJs);
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const { onSubmit } = this.props;
    onSubmit();
  }

  renderThankYou() {
    return <Fragment>
      <div className="row">
        <div className="col-md-6 col-lg-4 col-12">
          <h1>Thank You!</h1>
        </div>
        <div className="col-md-6 col-lg-8 col-12" style={{ paddingRight: '140px' }}>
          <h2>You won't actually be charged for BlitzPrep.</h2>
          <p>We're still working on pricing and your feedback is appreciated. We'll let you know when you can actually buy our services.</p>
          <h3>Plus, you'll get a special discount for helping us out!</h3>
          <bre />
          <LinkInner to={postSurveyPath} extraClass={'btn-link'} title={'Continue'} />
        </div>
      </div>
    </Fragment>
  }

  renderForm() {
    const {
      cardNumber,
      expiry,
      cvc,
      name,
      postalCode,
      onPostalCodeChanged,
      focused,
      onFocusChanged } = this.props;

    return <Fragment>
      <div className="row">
        <div className="col-md-6 col-lg-4 col-12">
          <Cards
            number={cardNumber}
            name={name}
            expiry={expiry}
            cvc={cvc}
            focused={focused}
          />
        </div>
        <div className="col-md-6 col-lg-8 col-12" style={{ paddingRight: '140px' }}>
          <StripeProvider stripe={this.state.stripe}>
            <Elements>
              <form onSubmit={this.handleSubmit}>
                <div className="form-group">
                  <label>Card number</label>
                  <CardNumberElement
                    style={{ base: { fontSize: '18px' } }}
                    onFocus={() => onFocusChanged('number')}
                  />
                </div>
                <div className="form-row">
                  <div className="form-group col-md-4">
                    <label>Expiration</label>
                    <CardExpiryElement
                      style={{ base: { fontSize: '18px' } }}
                      onFocus={() => onFocusChanged('expiry')}
                    />
                  </div>
                  <div className="form-group col-md-4">
                    <label>CVC</label>
                    <CardCVCElement
                      style={{ base: { fontSize: '18px' } }}
                      onFocus={() => onFocusChanged('cvc')}
                    />
                  </div>
                  <div className="form-group col-md-4">
                    <label>Postal Code</label>
                    <PostalCodeElement
                      value={postalCode}
                      style={{ base: { fontSize: '18px' } }}
                      onFocus={() => onFocusChanged('postalCode')}
                      onChange={event => onPostalCodeChanged(event.value)}
                    />
                  </div>
                </div>
                <button>Confirm order</button> &nbsp; <LinkInner to={postSurveyPath} extraClass={'btn-link'} title={'Skip'} style={{ marginTop: '-3px' }} />
              </form>
            </Elements>
          </StripeProvider>
        </div>
      </div>
    </Fragment>;
  }

  render() {
    const { submitted } = this.props;

    return <Layout>
      <Header><Navigation /></Header>
      <section className="cover height-60 imagebg text-center" data-overlay="5">
        <BackgroundImage source={brian1} />
        <div className="container pos-vertical-center">
          <div className="row">
            <div className="col-md-12">
              <h1>Your <strong>transformation</strong> begins with a <em>single</em> step.</h1>
            </div>
          </div>
        </div>
      </section>
      <section className="bg--light">
        <div className="container">
          {submitted ? this.renderThankYou() : this.renderForm()}
        </div>
      </section>
    </Layout>;
  }
}

const mapStateToProps = state => ({
  ...state.payment,
  email: state.firebase.auth && state.firebase.auth.email,
  name: (state.firebase.profile && state.firebase.profile.displayName) || ''
});
const mapDispatchToProps = ({
  ...paymentActions
});

const Connected = connect(mapStateToProps, mapDispatchToProps)(Payment);
export default WithAuthorization(authCondition)(Connected);
