import React, { Component } from 'react';

class Brand extends Component {
  render() {
    return (
      <div className="footer-container pt-4 my-md-5 pt-md-5 border-top">
        {this.props.children}
      </div>
    );
  }
}

export default Brand;