import gaAnimationData from 'animations/geniusActivity.json';
import loadingData from 'animations/loading.json';

const baseAnimation = {
  loop: false,
  autoplay: true,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export const geniusActivity = {
  ...baseAnimation,
  animationData: gaAnimationData,
};

export const loading = {
  ...baseAnimation,
  loop: true,
  animationData: loadingData,
};
