import React, { Component } from 'react';

import Layout from 'components/Layout';

class NotFound extends Component {
  render() {
    return (<Layout>
      <section className="bg--dark text-center imagebg cover-fullscreen" data-overlay='4'>
        Error 404: Not Found
      </section>
    </Layout>);
  }
}

export default NotFound;
