import Pricing from './Pricing';
import PricingA from './PricingA';
import PricingB from './PricingB';

export {
  PricingA,
  PricingB
}

export default Pricing;
