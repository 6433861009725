import React, { Fragment } from 'react';

import WithAuthorization from 'components/WithAuthorization';
import { authCondition } from 'utils';

import { Teaser } from 'components/Screen';
import Masonry, { MasonryItem } from 'components/Masonry';

// Images
import shari from 'images/shari.jpg';
import accelerator2 from 'images/accelerator2.jpg';

class Shari extends Teaser {
  renderContent() {
    return (<Fragment>
        <section className="imagebg text-center" data-overlay="1" style={{backgroundColor: 'black'}}>
          <div className="container" style={{paddingTop: '30px'}}>
            <div className="row">
              <div className="col-md-12">
                <h1>Shari Acho</h1>
                <div className="col-md-12 col-lg-12">
                  <div className="col-md-12 col-lg-9" style={{margin: 'auto'}}>
                    <h4 style={{paddingBottom:'10px'}}>Meet Shari Acho, BlitzPrep Founder, and someone you will get very
                    familiar with over the course of 'BlitzPrep University'. Shari has over 30 years of veteran experience
                    helping athletes as the director of academic and professional success at univerities like the University of
                    Michigan and Michigan State.
                    </h4>

                  </div>
                  <img alt="Dashboard" src={shari} style={{width:'100%', paddingBottom:''}}/>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="unpad bg--dark" style={{ paddingBottom: '75px', backgroundColor:'black'}}>
          <Masonry extraClass="col-11">
            <MasonryItem
              title='The Foundation Studio'
              short='Detroit, MI'
              extraClass='col-lg-4 col-md-6 col-12'
              imageSrc={accelerator2} />

            <MasonryItem
              title='Qunicy Adams'
              short='Former NFL Defensive End'
              extraClass='col-lg-8 col-md-6 col-12'
              imageSrc={accelerator2} />

            <MasonryItem
              title='Denard Robinson'
              short='Former NFL Running Back'
              extraClass='col-lg-8 col-md-6 col-12'
              imageSrc={accelerator2} />

            <MasonryItem
              title='Mike Martin'
              short='Former NFL Defensive End'
              extraClass='col-lg-4 col-md-6 col-12'
              imageSrc={accelerator2} />
          </Masonry>
        </section>

      </Fragment>);
  }
}


export default WithAuthorization(authCondition)(Shari);
