import React, { Component, Fragment } from 'react';

import LinkInner from 'components/LinkInner';

import { paymentPath, postSurveyPath } from 'constants/routes';

class PricingB extends Component {
  render() {
    return (<Fragment>
      <section className="bg--light">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-4 col-12">
              <div className="pricing pricing-1 boxed boxed--border boxed--lg text-center">
                <h4>Early-Beta Pricing</h4>
                <span className="h1">
                  <span className="pricing__dollar">$</span>49.99</span>
                <ul className="accordion accordion-2 accordion--oneopen" style={{ marginBottom: '2em' }}>
                  <li className="active">
                    <div className="accordion__title">
                      <span className="h5">BLITZPREP UNIVERSITY</span>
                    </div>
                    <div className="accordion__content">
                      <span>• 20 interactive episodes</span><br />
                      <span>• 15 hours of content</span><br />
                    </div>
                  </li>
                  <li>
                    <div className="accordion__title">
                      <span className="h5">BLITZPREP STUDIOS CONTENT</span>
                    </div>
                    <div className="accordion__content">
                      <span>• Series</span><br />
                      <span>• Documentaries</span><br />
                      <span>• Podcasts</span><br />
                    </div>
                  </li>
                  <li>
                    <div className="accordion__title">
                      <span className="h5">EVENTS</span>
                    </div>
                    <div className="accordion__content">
                      <span>• Early access</span><br />
                      <span>• Special pricing</span><br />
                      <span>• Exclusive offerings</span><br />
                    </div>
                  </li>
                  <li>
                    <div className="accordion__title">
                      <span className="h5">BLITZPREP LOCKER ROOM</span>
                    </div>
                    <div className="accordion__content">
                      24/7 access to hundreds of new teammates
                                    </div>
                  </li>
                  <li>
                    <div className="accordion__title">
                      <span className="h5">MEMBER DISCOUNTS</span>
                    </div>
                    <div className="accordion__content">
                      <span>• <strong>Career and Life Services (see right →)</strong></span><br />
                      <span>• Partnered brands (e.g. clothing)</span><br />
                      <span>• Partnered services (e.g. financial)</span><br />

                    </div>
                  </li>
                </ul>
                <span className="label">Special</span>
                <LinkInner to={paymentPath} className="btn btn--primary" title={'Purchase Now'} style={{ fontSize: '16px' }} />
              </div>
              <LinkInner to={postSurveyPath} extraClass={'btn-link btn-block'} title={'Skip'} />
            </div>
            <div className="col-lg-8 col-md-6" style={{ paddingLeft: '140px' }}>
              <div className="row" style={{ paddingBottom: '40px' }}>
                <div className="col-lg-12">
                  <h2>Career and Life Services</h2>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-5 col-md-6">
                  <div className="feature feature-6" style={{ marginBottom: '0px' }}>
                    <i className="icon icon--sm icon-Preview color--primary"></i>
                    <h5>Career Services</h5>
                    <p>
                      Job portal, job coaching, and guidance. Resume, cover letter, LinkedIn review.
                                    </p>
                  </div>
                </div>
                <div className="col-lg-5 col-md-6">
                  <div className="feature feature-6" style={{ marginBottom: '0px' }}>
                    <i className="icon icon--sm icon-Road-3 color--primary"></i>
                    <h5>Coaching</h5>
                    <p>
                      Peer, expert, and mentors to help guide you through the challenges of athlete transition.
                                    </p>
                  </div>
                </div>
                <div className="col-lg-5 col-md-6">
                  <div className="feature feature-6">
                    <i className="icon icon--sm icon-Pen color--primary"></i>
                    <h5>Accelerator</h5>
                    <p>
                      Take your idea, business, or product to the next level.
                                    </p>
                  </div>
                </div>
                <div className="col-lg-5 col-md-6">
                  <div className="feature feature-6">

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>);
  }
}

export default PricingB;
