import React, { Component } from 'react';

import WithAuthorization from 'components/WithAuthorization';
import TypeformEmbed from 'components/TypeformEmbed';
import Layout, { Header, Content } from 'components/Layout';
import Loading from 'components/Loading';
import { authCondition, identifyMeOnTypeform } from 'utils';
import { homePath } from 'constants/routes';

class PostSurvey extends Component {
  onSubmit() {
    const { history } = this.props;
    history.push(homePath);
  }

  render() {
    const { isLoaded } = this.props.profile;
    if (!isLoaded) {
      return <Loading />;
    }

    const url = identifyMeOnTypeform(this.props, 'https://blitzprep.typeform.com/to/wgSo9Z');

    return (<Layout>
      <Header isLogoLink={false}>
        <div />
      </Header>
      <Content>
        <section className="bg--dark text-center imagebg cover-fullscreen" data-overlay='4'>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-10 col-lg-8">
                <h2 className="text-center">BlitzPrep Post-Survey</h2>
              </div>
            </div>
            <div className="col-md-10 col-lg-12">
              <h4>Please fill out this survey about the BlitzPrep Beta Experience. Your<br />answers will help us better impact athletes around the world.</h4>
              <TypeformEmbed url={url} style={{ width: '100%', height: '500px', paddingTop: '0px', marginBottom: '-50px' }} onSubmit={() => this.onSubmit()} />
            </div>
          </div>
        </section>
      </Content>
    </Layout>);
  }
}

export default WithAuthorization(authCondition)(PostSurvey);
