import React, { Component } from 'react';
import _ from 'lodash';
import { Helmet } from "react-helmet";
import * as Sentry from '@sentry/browser';

import Anchor from 'components/Anchor';
import { Header, Content, Footer } from '.';
import LinkInner from 'components/LinkInner';

class Layout extends Component {
    componentDidMount() {
        if (window.mr) {
            window.mr.status.windowLoadPending = true;
            window.mr.documentReady();
        }
    }

    componentDidCatch(error, errorInfo) {
        // Let's see if here is a good place to log to any crash reporting tool, such as sentry
        if (process.env.NODE_ENV !== 'development') {
            Sentry.withScope(scope => {
                Object.keys(errorInfo).forEach(key => {
                    scope.setExtra(key, errorInfo[key]);
                });
                Sentry.captureException(error);
            });
        }
    }

    render() {
        let children = _.isArray(this.props.children)
            ? this.props.children
            : this.props.children ? [this.props.children] : [];
        // If there is a content tag in the layout, let's use that!!! and ignore the rest.
        const content = _.findLast(children, child => child.type === Content);
        // We want a custom header
        const header = _.findLast(children, child => child.type === Header);
        // You can put the footer outside the content :D. ie. signup.html
        // If you want it inside the content, default, put the Footer tag
        // inside the Content tag
        const footer = _.findLast(children, child => child.type === Footer);
        // There is no content then anything but Header and Footer is the content
        if (!content) {
            children = _.filter(children, child => !_.includes([Header, Footer], child.type));
        }

        return (
            <div className="App">
                <Helmet>
                    <title>BlitzPrep University</title>
                </Helmet>
                <Anchor tag={'start'} />
                {header || <Header />}
                {content || <Content>{children}</Content>}
                {/* Footer is optional */}
                {footer}
                <LinkInner className="back-to-top inner-link" path="#start" data-scroll-class="100vh:active">
                    <i className="stack-interface stack-up-open-big"></i>
                </LinkInner>
            </div >
        )
    }
}

export default Layout;