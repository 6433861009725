import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Player from '@vimeo/player';
import * as Sentry from '@sentry/browser';

class Video extends Component {
  static propTypes = {
    // A list of video with the content type as key and the url as value
    source: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
    autoPlay: PropTypes.bool,
    loop: PropTypes.bool,
    muted: PropTypes.bool,
    title: PropTypes.string
  };

  createVideoService() {
    if (this.el) {
      const { source } = this.props;
      const autoPlay = this.props.autoPlay || false;
      const loop = this.props.loop || false;
      const muted = this.props.muted || false;
      try {
        this.player = new Player(this.el, {
          url: source,
          responsive: true,
          color: 'ffffff',
          title: false,
          byline: false,
          autoplay: autoPlay,
          loop: loop,
          muted: muted
        });
      } catch (err) {
        if (process.env.NODE_ENV !== 'development') {
          Sentry.captureException(err);
        } else {
          console.log('Video', err);
        }
      }
    }
  }

  loadVideoService() {
    if (this.player) {
      this.player.destroy();
      this.player = null;
      this.el.innerHTML = '';
    }
    this.createVideoService();
  }

  componentDidMount() {
    this.loadVideoService();
  }

  componentWillUnmount() {
    this.player && this.player.destroy();
  }

  componentDidUpdate() {
    this.loadVideoService();
  }

  renderVideoService() {
    return <div ref={el => this.el = el} />;
  }

  renderVideo() {
    const { source } = this.props;
    const autoPlay = this.props.autoPlay || false;
    const loop = this.props.loop || false;
    const muted = this.props.muted || false;
    return (<video autoPlay={autoPlay} loop={loop} muted={muted}>
      {_.map(_.keys(source), (format, idx) => (<source key={idx} src={source[format]} type={format} />))}
    </video>);
  }

  render() {
    const { source } = this.props;

    return _.isObject(source)
      ? this.renderVideo() : this.renderVideoService();
  }
}

export default Video;
