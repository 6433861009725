import React, { Fragment } from 'react';

import WithAuthorization from 'components/WithAuthorization';
import { Teaser } from 'components/Screen';
import { authCondition } from 'utils';

import Masonry, { MasonryItem } from 'components/Masonry';
import Anchor from 'components/Anchor';
import LinkInner from 'components/LinkInner';

// Images
import community1 from 'images/community1.jpg';
import locker1 from 'images/locker1.jpg';
import locker2 from 'images/locker2.jpg';
import locker3 from 'images/locker3.jpg';
import locker4 from 'images/locker4.jpg';
import locker5 from 'images/locker5.jpg';
import locker6 from 'images/locker6.jpg';
import locker7 from 'images/locker7.jpg';
import locker8 from 'images/locker8.jpg';

class Community extends Teaser {
  renderContent() {
    return (
      <Fragment>
        <section className="imagebg text-center" data-overlay="1" style={{backgroundColor: 'black'}}>
          <div className="container" style={{paddingTop: '30px'}}>
            <div className="row">
              <div className="col-md-12">
                <h1>BlitzPrep Locker Room</h1>
                <div className="col-md-12 col-lg-12">
                  <div className="col-md-12 col-lg-9" style={{margin: 'auto'}}>
                    <h4 style={{paddingBottom:'10px'}}>Welcome, As football players, we know that the locker room is a sacred space. Welcome to your digital locker room, where conversations can be had from anywhere in the world with the only people who know your world.
                    Here you will easily find discussions based on categories of interest, like transitioning from football, starting a new business, and meeting up at new club opening. But most importantly, you’ll experience comradery exclusively from verified football players like yourself.
                    </h4>
                    <LinkInner title={'Join Now'} path={'#form'} style={{marginBottom:'30px'}} />
                  </div>
                  <img alt="Dashboard" src={community1} style={{width:'100%', paddingBottom:''}}/>
                  <LinkInner title={'Get Started'} path={'#form'} style={{marginBottom:'30px'}} />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="unpad bg--dark" style={{ paddingBottom: '75px', backgroundColor:'black'}}>
          <Masonry extraClass="col-11">
            <MasonryItem
              title='Casey Theriault'
              short='Braunchschweig New Yorker Lions'
              extraClass='col-lg-4 col-md-6 col-12'
              imageSrc={locker1} />

            <MasonryItem
              title='Mike James'
              short='Detroit Lions'
              extraClass='col-lg-8 col-md-6 col-12'
              imageSrc={locker2} />

            <MasonryItem
              title='Darrian Harris'
              short='Former NFL Running Back'
              extraClass='col-lg-8 col-md-6 col-12'
              imageSrc={locker3} />

            <MasonryItem
              title='Gerald Holmes'
              short='Seattle Seahawks'
              extraClass='col-lg-4 col-md-6 col-12'
              imageSrc={locker4} />

            <MasonryItem
              title='Chris Frey'
              short='Carolina Panthers'
              extraClass='col-lg-4 col-md-6 col-12'
              imageSrc={locker5} />

            <MasonryItem
              title='Ryan Van Bergen'
              short='Carolina Panthers'
              extraClass='col-lg-8 col-md-6 col-12'
              imageSrc={locker6} />

            <MasonryItem
              title='Find your locker room again'
              short=''
              extraClass='col-lg-8 col-md-6 col-12'
              imageSrc={locker7} />

            <MasonryItem
              title='Shari Acho'
              short=''
              extraClass='col-lg-4 col-md-6 col-12'
              imageSrc={locker8} />
          </Masonry>
        </section>
        <section className="text-center">
          <div className="container">
            <Anchor tag="form" />
            <h1>This feature has not launched yet!</h1>
          </div>
        </section>
      </Fragment>
    );
  }
}


export default WithAuthorization(authCondition)(Community);
