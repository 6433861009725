import React from 'react';
import PropTypes from 'prop-types';

import Screen from './Screen';
import Layout, { Navigation, Header } from 'components/Layout';

class Teaser extends Screen {
  static propTypes = {
    embed: PropTypes.bool,
  };

  renderContent() {
    return <div>Please override renderContent function in the component with the actual content.</div>
  }

  render() {
    const { embed } = this.props;
    if (embed) {
      return this.renderContent();
    }
    
    return (
      <Layout>
        <Header><Navigation/></Header>
        {this.renderContent()}
      </Layout>
    );
  }
}

export default Teaser;
