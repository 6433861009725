const firebase = {
  apiKey: 'AIzaSyB3MqpV8LAoW9Z3m7OARGT1V7pQ4hu39ws',
  authDomain: 'blitzprep-university.firebaseapp.com',
  databaseURL: 'https://blitzprep-university.firebaseio.com',
  projectId: 'blitzprep-university',
  storageBucket: 'blitzprep-university.appspot.com',
  messagingSenderId: '20599451437'
}

const googleAnalytics = {
  trackingId: 'UA-115867105-3'
};

const sentry = {
  dsn: 'https://3a3b490915eb4227aaaf454004f3761a@sentry.io/1332172',
  release: 'blitzprep-university@9f7f18d052350e716850e6df0310d790d1aeebfa'
};

const abTesting = {
  enabled: true
};

const domain = 'university.blitzprep.com';
const site = {
  domain,
  url: `https://${domain}`
};

const stripe = {
  apiKey: 'pk_test_MVBvV7CUKW5r82sWNN8mcqPA'
};

export {
  firebase,
  googleAnalytics,
  sentry,
  abTesting,
  site,
  stripe
};
