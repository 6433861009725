import React, {Component} from 'react';

class Footer extends Component {
    render() {
      const { children } = this.props;
      if (children) {
        return (
          <footer className="text-center space--sm footer-5 bg--dark">
            {children}
          </footer>
        );
      }

      return null;
    }
  }

export default Footer;