import React, { Component } from 'react';
import PropTypes from 'prop-types';


class Anchor extends Component {
  static propTypes = {
    tag: PropTypes.string
  }

  render() {
    const { tag } = this.props;
    // eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid
    return (<a id={tag}></a>);
  }
}

export default Anchor;
