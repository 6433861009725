import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Feature extends Component {
  static propTypes = {
    title: PropTypes.string,
    type: PropTypes.string,
    content: PropTypes.string
  }

  getDefault(type) {
    switch (type) {
        case 'learning outcomes':
          return 'Learning Outcomes';
        case 'reflection':
          return 'Reflection';
        case 'awareness':
          return 'Awareness';
        case 'custom':
        default:
          return '';
    }
  }

  render() {
    const { title, type, content } = this.props;
    return (
        <div className="feature">
          <h4>{title ? title : this.getDefault(type)}</h4>
          <p>{content}</p>
        </div>
    );
  }
}

export default Feature;
