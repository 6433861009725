import React, { Fragment } from 'react';

import WithAuthorization from 'components/WithAuthorization';
import { Teaser } from 'components/Screen';
import { authCondition } from 'utils';

// Images
import dashboard from 'images/dashboard.jpg';

class Overview extends Teaser {
  renderContent() {
    return (<Fragment>
        <section className="imagebg text-center" data-overlay="1" style={{backgroundColor: 'black'}}>
          <div className="container" style={{paddingTop: '30px'}}>
            <div className="row">
              <div className="col-md-12">
                <h1>BlitzPrep Features Preview</h1>
                <div className="col-md-12 col-lg-12">
                  <div className="col-md-12 col-lg-9" style={{margin: 'auto'}}>
                    <h4 style={{paddingBottom:'10px'}}>Now, you will get an exclusive sneak peek at upcoming BlitzPrep features and services. Please give us your open and honest
                    feedback and let us know how which ones you find valuable and exciting.</h4>
                  </div>
                  <img alt="Dashboard" src={dashboard} style={{width:'100%', paddingBottom:'40px'}}/>                
                </div>
              </div>
            </div>
          </div>
        </section>

      </Fragment>);
  }
}


export default WithAuthorization(authCondition)(Overview);
