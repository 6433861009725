import React, { Fragment } from 'react';
import _ from 'lodash';
import { compose } from 'redux';
import { firestoreConnect, isLoaded } from 'react-redux-firebase';
import { connect } from 'react-redux';
import Lottie from 'react-lottie';
import { Helmet } from "react-helmet";
import * as moment from 'moment';

import Screen from 'components/Screen';
import Layout from 'components/Layout';
import BackgroundImage from 'components/BackgroundImage';
import TypeformEmbed from 'components/TypeformEmbed';
import Video from 'components/Video';
import Wizard, { WizardItem } from 'components/Wizard';
import Button from 'components/Button';
import WithAuthorization from 'components/WithAuthorization';
import contentType from 'constants/contentType';
import galleryLayout from 'constants/galleryLayout';
import { geniusActivity } from 'constants/animations';
import * as collections from 'constants/collections';
import { events } from 'constants/activity';
import { authCondition, identifyMeOnTypeform } from 'utils';
import Timeline, { TimelineItem } from 'components/Timeline';
import Masonry, { MasonryItem } from 'components/Masonry';
import Slider, { SliderItem } from 'components/Slider';
import { Header } from 'components/Layout';
import Loading from 'components/Loading';

import { actions as sessionActions } from 'reducers/session';

// For teasers
import {
  coachingPath,
  eventsPath,
  podcastsPath,
  jobsPath,
  acceleratorPath,
  pricingPath,
  shariPath,
  overviewPath,
  communityPath,
  studiosPath
} from 'constants/routes';
import Coaching from 'screens/Coaching';
import Events from 'screens/Events';
import Podcasts from 'screens/Podcasts';
import Jobs from 'screens/Jobs';
import Accelerator from 'screens/Accelerator';
import Shari from 'screens/Shari';
import Overview from 'screens/Overview';
import Community from 'screens/Community';
import Studios from 'screens/Studios';

// List here all the available teaser pages, make sure to support embed prop, take a look at Coaching
const availableTeasers = {
  [coachingPath]: Coaching,
  [eventsPath]: Events,
  [podcastsPath]: Podcasts,
  [jobsPath]: Jobs,
  [acceleratorPath]: Accelerator,
  [shariPath]: Shari,
  [overviewPath]: Overview,
  [communityPath]: Community,
  [studiosPath]: Studios,
};

class Page extends Screen {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getEpisodeLastPage(nextProps = null) {
    const { profile, match, session } = (nextProps || this.props);
    const { params: { episodeId } } = match;
    return session.episodeNav[episodeId] || (profile.progress && profile.progress[episodeId] && profile.progress[episodeId].pageId) || 0;
  }

  handleStart(nextProps = null) {
    const { match, history } = (nextProps || this.props);
    const { url, params: { episodeId, pageId } } = match;

    // Let's track if user already
    const sessionTimeExpiry = parseFloat(localStorage.getItem(url) || '0');
    const now = parseFloat(moment.utc().format("X"));
    localStorage.setItem(url, moment.utc().add(30, 'minutes').format("X"));
    if (sessionTimeExpiry && sessionTimeExpiry > now) {
      return;
    }

    const lastPageId = this.getEpisodeLastPage(nextProps);
    if (pageId > lastPageId) {
      // Request one by one
      if (parseInt(pageId) > (lastPageId + 1)) {
        const path = `page-${lastPageId + 1}`;
        // TODO: send an error message about not being able to jump pages.
        history.push(path);
        return;
      }
      const data = {
        episodeId,
        pageId
      }
      const { profile: { progress} } = (nextProps || this.props);
      if (!(progress && progress[episodeId])) {
        this.trackActivity({ event: events.EPISODE_STARTED, data }, nextProps);
      }
      this.trackActivity({ event: events.EPISODE_PAGE_STARTED, data }, nextProps);
    }
  }

  handleCompletion() {
    const { episode, match: { params: { episodeId, pageId } } } = this.props;
    const pageIdInt = parseInt(pageId);
    const lastPageId = this.getEpisodeLastPage();
    if (lastPageId < pageIdInt) {
      const data = {
        episodeId,
        pageId
      };
      this.trackActivity({ event: events.EPISODE_PAGE_COMPLETED, data });
      this.setState({submitted: false});
      if (episode.content && episode.content.length === pageIdInt) {
        this.trackActivity({ event: events.EPISODE_COMPLETED, data });
      }
      this.props.changeEpisodePage(episodeId, pageId);
    }
  }

  handleContinueButton(nextProps = null) {
    const { episode, match: { params: { pageId } } } = nextProps || this.props;
    if (episode) {
      const content = this.getContent(nextProps);
      switch (content.type) {
        case contentType.GENIUS_ACTIVITY:
          const lastPageId = this.getEpisodeLastPage(nextProps);
          if (pageId > lastPageId) {
            this.setState({ disabledContinue: !this.state.submitted && !!content.url });
          } else {
            this.setState({ disabledContinue: false });
          }
          break;
        default:
          this.setState({ disabledContinue: false });
          break;
      }
    }
  }

  componentWillUpdate(nextProps) {
    this.handleStart(nextProps);
  }

  componentWillMount() {
    this.handleStart();
  }

  // You can use prevProps, prevState, snapshot
  componentDidUpdate() {
    if (window.mr) {
      window.mr.status.windowLoadPending = true;
      window.mr.cleanLeftOver();
      window.mr.documentReady();
    }
  }

  renderContentGeniusActivity(content) {
    const { isLoaded } = this.props.profile;
    if (!isLoaded) {
      return null;
    }

    const { match: { params: { pageId } } } = this.props;
    const lastPageId = this.getEpisodeLastPage();
    let typeForm;
    if ((this.state && this.state.submitted) || pageId <= lastPageId) {
      typeForm = <div className={'form-submitted'}>Thanks! Please continue the beta.</div>
    } else if (!!content.url) {
      const url = identifyMeOnTypeform(this.props, content.url);
      typeForm = <TypeformEmbed url={url} style={{ width: '100%', height: '500px', paddingTop: '0' }}
        onSubmit={() => this.enableContinue()} />
    } else {
      typeForm = <h2>This feature is not available yet!</h2>
    }

    return (<div className="container">
      <div className="row justify-content-center">
        <div className="col-md-10 col-lg-8">
          <h2 className="text-center">{content.heading}</h2>
          <Lottie options={geniusActivity} height={200} width={200} style={{ marginTop: '-48px', marginBottom: '-24px' }} />
          {/* TODO: Remove content.content */}
          <p className="lead" dangerouslySetInnerHTML={{ __html: (content.description || content.content) }} />
        </div>
      </div>
      <div className="col-md-10 col-lg-12">
        <h4>{content.subheading}</h4>
        {typeForm}
      </div>
    </div>);
  }

  renderContentWizard(content) {
    return (
      <div>
        <div className="container" style={{ paddingBottom: '0' }}>
          <div className="row justify-content-center text-center">
            <div className="col-md-12 col-lg-12">
              <h1 style={{ margin: 'auto' }}>{content.heading}</h1>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-8">
              <Wizard>
                {content.picture.map((episode, idx) => <WizardItem key={idx} {...episode} />)}
              </Wizard>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderContentVideo(content) {
    return (<div className="container">
      <div className="row">
        <div className="col-md-12">
          <h1>{content.heading}</h1>
        </div>
        <div className="col-md-12 col-lg-12">
          <div>{content.description}</div>
          <div>
            {content.url && <Video title={content.heading} source={content.url} autoPlay />}
          </div>
        </div>
      </div>
    </div>);
  }

  renderContentPage(content) {
    const multipleImages = content.picture && content.picture.length > 1 ? this.renderContentPageImages(content) : null;
    let onlyImage;
    if (!content.picture || content.picture.length < 2) {
      if (content.picture && content.picture.length === 1) {
        onlyImage = content.picture && content.picture.length === 1 ? (<img src={content.picture[0].url} alt={content.picture[0].title} style={{ paddingBottom: '1em' }} />) : null;
      } else {
        onlyImage = (<img alt={content.heading} src={content.thumbnail} style={{ paddingBottom: '1em' }} />);
      }
    }

    return (
      <Fragment>
        <div className="container" style={{ paddingBottom: '5em' }}>
          <div className="row">
            <div className="col-md-12">
              <h1>{content.heading}</h1>
            </div>
          </div>
        </div>
        {multipleImages}
        {/* TODO: Remove content.content */}
        {(onlyImage || content.subheading || (content.description || content.content)) &&
          <div className="container" style={{ paddingTop: '0', paddingBottom: '3em' }}>
            <div className="row justify-content-center">
              <div className="col-md-10 col-lg-12">
                {onlyImage}
                <h2 className="text-center">{content.subheading}</h2>
                {/* TODO: Remove content.content */}
                <p className="lead">{content.description || content.content}</p>
              </div>
            </div>
          </div>}
        {content.gallery && (content.galleryLayout !== galleryLayout.MASONRY ? this.renderContentPageGalleryTimeline(content) : this.renderContentPageGalleryMasonry(content))}
      </Fragment >
    )
  }

  renderContentPageImages(content) {
    return (<Slider extraClass="cover cover-fullscreen height-100 imagebg text-center"
      paging={false}
      dataTiming={99999999999}
      style={{ pointerEvents: 'none', paddingBottom: '2em' }}>
      {content.picture.map((item, idx) => (<SliderItem key={idx}
        image={item.url}
        className="imagebg col-lg-4 col-md-6 col-12"
        heading={item.title}
        description={item.content}
        clickable={false}
        grayOut={true}
      />))}
    </Slider>);
  }

  renderContentPageGalleryTimeline(content) {
    return (
      <section className="bg--dark" style={{ paddingTop: '0', paddingBottom: '0' }}>
        <div className="container">
          <div className="row justify-content-center">
            <Timeline>
              {content.gallery.map((item, i) => (<TimelineItem key={i}
                title={item.title}
                imageSrc={item.url}
                short={item.content}
              />
              ))}
            </Timeline>
          </div>
        </div>
      </section>
    )
  }

  renderContentPageGalleryMasonry(content) {
    const { gallery } = content;

    return (<section className="unpad bg--dark" style={{ paddingBottom: '0' }}>
      <Masonry extraClass="col-11">{gallery.map((item, idx) => {
        // (index + 1 (Position) / 4 (variations) % 1 (to get the decimal)) * 4 (variations)
        // For example (((4 + 1)/4)%1) * 4 = 1
        const pattern = (((idx + 1) / 4) % 1) * 4;
        const extraClass = 'col-md-6 col-12 ' + ([2, 3].includes(pattern) ? 'col-lg-8' : 'col-lg-4');
        return (<MasonryItem key={idx}
          title={item.title}
          short={item.content}
          extraClass={extraClass}
          imageSrc={item.url} />);
      })}
      </Masonry>
    </section>)
  }

  renderContentTeaser(content) {
    const { url } = content;
    if (url && url in availableTeasers) {
      const Teaser = availableTeasers[url];
      return <Teaser embed />;
    }

    return null;
  }

  renderContent() {
    const content = this.getContent();
    switch (content.type) {
      case contentType.VIDEO:
        return this.renderContentVideo(content);
      case contentType.WIZARD:
        return this.renderContentWizard(content);
      case contentType.GENIUS_ACTIVITY:
        return this.renderContentGeniusActivity(content);
      case contentType.PAGE:
        return this.renderContentPage(content);
      case contentType.TEASER:
        return this.renderContentTeaser(content);
      default:
        return null;
    }
  }

  renderNavigation(inside = false) {
    const { match: { params: { pageId } }, episode } = this.props;
    let prevPage = null;
    let nextPage = null;
    if (parseInt(pageId) > 1) {
      prevPage = episode.content[pageId - 2];
    }
    if (parseInt(pageId) <= episode.content.length) {
      nextPage = episode.content[parseInt(pageId)];
    }

    let extraClass = [pageId === '1' ? ' btn--stroked' : ''];
    extraClass.push(!!this.state && !!this.state.disabledContinue ? 'disabled' : '');

    let nextTooltip = nextPage ? nextPage.heading : null;
    if (!!this.state && !!this.state.disabledContinue) {
      nextTooltip = 'Complete Genius Activity to Continue';
    }

    if (inside) {
      return (<div className="container"><div className="row justify-content-center">
        <div className="col-md-12 col-lg-10" style={{ paddingTop: '20px' }}>
          {pageId !== '1' && <Button text={'Back'} path={`page-${parseInt(pageId) - 1}`}
            extraClass={'btn--stroked'} align={'left'} tooltip={prevPage ? prevPage.heading : ''} />}
          <Button text={'Continue'} path={nextPage ? `page-${parseInt(pageId) + 1}` : pricingPath}
            extraClass={` ${extraClass.join(' ')}`}
            align={'right'} tooltip={nextTooltip}
            onClick={() => this.handleCompletion()} />
        </div>
      </div></div>);
    }

    return (
      <section className="bg--dark" style={{ marginTop: '0px' }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10 col-lg-10">
              {pageId !== 1 && <Button text={'Back'} path={`page-${parseInt(pageId) - 1}`}
                extraClass={'btn--stroked'} align={'left'} tooltip={prevPage ? prevPage.heading : ''} />}
              <Button text={'Continue'} path={nextPage ? `page-${parseInt(pageId) + 1}` : pricingPath}
                extraClass={` ${extraClass.join(' ')}`}
                align={'right'} tooltip={nextTooltip}
                onClick={() => this.handleCompletion()} />
            </div>
          </div>
        </div>
      </section>
    );
  }

  getContent(nextProps = null) {
    const { match: { params: { pageId } }, episode } = (nextProps || this.props);
    const { content } = episode;
    return content[pageId - 1];
  }

  componentWillReceiveProps(nextProps) {
    this.handleContinueButton(nextProps);
  }

  render() {
    const { episode } = this.props;
    if (!isLoaded(episode)) {
      return (<Loading />);
    }

    const content = this.getContent();
    const inside = [contentType.VIDEO, contentType.WIZARD, contentType.GENIUS_ACTIVITY].includes(content.type);
    const multipleBgColors = content && content.bgColor && content.bgColor.split(',').length > 1;
    const sectionProps = {};
    if (multipleBgColors) {
      sectionProps['data-gradient-bg'] = content.bgColor;
    } else if (content.bgColor) {
      sectionProps['style'] = { backgroundColor: content.bgColor };
    }

    return (
      <Layout>
        <Helmet>
          <title>{episode.heading} | BlitzPrep University</title>
          <meta name="description" content={episode.short} />
        </Helmet>
        <Header back={true} />
        {/* bg--dark was not used on video */}
        <section className="bg--dark text-center imagebg cover-fullscreen" {...sectionProps} data-overlay='4'>
          {content.background && <BackgroundImage source={content.background} />}
          {this.renderContent()}
          {inside && this.renderNavigation(true)}
        </section>
        {!inside && this.renderNavigation()}
      </Layout>
    );
  }

  enableContinue() {
    const disabledContinue = false;
    this.setState({
      disabledContinue,
      submitted: !disabledContinue
    });
  }
}

const mapStateToProps = ({ session, firestore: { ordered } }) => ({
  session,
  episode: _.first(ordered.episodes || []),
});
const mapDispatchToProps = ({
  ...sessionActions,
});

const FirestoreConnected = compose(
  firestoreConnect(({ match }) => [
    { collection: collections.EPISODE, doc: match.params.episodeId }
  ]),
  connect(mapStateToProps, mapDispatchToProps)
)(Page);
export default WithAuthorization(authCondition)(FirestoreConnected);
