import React, { Component } from 'react';
import PropTypes from 'prop-types';

class BackgroundImage extends Component {
  static propTypes = {
    source: PropTypes.string,
    parallax: PropTypes.bool,
  };

  componentDidMount() {
    const { $, mr_parallax } = window;
    const { parallax } = this.props;
    this.$el = $(this.el);
    
    // Append .background-image-holder <img>'s as CSS backgrounds
    const imgSrc = this.$el.children('img').attr('src');
    this.$el.css({
      'background': `url("${imgSrc}")`,
      'background-position': 'initial',
      'opacity': '1',
    });

    // Parallax
    if (parallax) {
      const $window = $(window);
      const windowWidth = $window.width();
      const windowHeight = $window.height();
      const navHeight = $('nav').outerHeight(true);
      const parallaxHero = this.$el.parent('.parallax:nth-of-type(1)');

      if (windowWidth > 768) {
        this.$el.css('top', -(navHeight));
        if (parallaxHero.outerHeight(true) === windowHeight) {
          this.$el.css('height', windowHeight + navHeight);
        }
      }
      mr_parallax.profileParallaxElements();
      mr_parallax.mr_parallaxBackground();
    }
  }

  render() {
    const { source } = this.props;
    return (
      <div ref={el => this.el = el} className="background-image-holder">
        <img alt="background" src={source} />
      </div>
    );
  }
}

export default BackgroundImage;
